<template>
  <i class="fas fa-info-circle" @click.stop="showReservationsForDate()">
    <b-modal
      :ref="'modal-' + spotId + '-' + date"
      :size="reservations.length > 1 ? 'lg' : 'sm'"
      header-class="border-0 h-25 pb-0"
      footer-class="border-0"
      body-class="p-0"
    >
      <template v-slot:modal-header="{ close }">
        <div class="container-fluid">
          <div class="row">
            <div class="col-9">
              <h3>Reservations</h3>
            </div>
            <div class="col-3">
              <a
                role="button"
                href="javascript:void(0);"
                @click="close"
                class="float-right"
              >
                Close
                <i class="fas fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <div v-if="!loading" class="p-3">
        <div class="row">
          <div
            v-for="r in reservations"
            :key="r.id"
            :class="reservations.length > 1 ? 'col-lg-6' : 'col-lg-12'"
          >
            <div class="text-center justify-content-center">
              <h4>
                {{ r.startDate | formatDateAsUTCDate }} -
                {{ r.endDate | formatDateAsUTCDate }}
              </h4>
              <a
                :href="'/admin/reservation-details/' + r.id"
                target="_blank"
                title="Go to reservation"
                >{{ r.reservationNumber }}</a
              >
              <p>{{ r.contacts[0].firstName }} {{ r.contacts[0].lastName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </i>
</template>

<script>
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";

export default {
  name: "AdminReservationsOnDateModal",
  data() {
    return {
      reservations: [],
      loading: false
    };
  },
  props: {
    spotId: Number,
    date: String
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    showReservationsForDate() {
      this.loading = true;
      this.$refs[`modal-${this.spotId}-${this.date}`].show();
      this.getReservationsForDate();
    },
    async getReservationsForDate() {
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      this.reservations = await spotReservationService.getReservationsByDate(
        this.spotId,
        this.date
      );
      this.loading = false;
    },
    routeToReservation(id) {
      this.$router.push("/admin/reservation-details/" + id).catch(() => {});
    }
  }
};
</script>

<style scoped>
.fa-info-circle {
  color: black;
  cursor: pointer;
}
.reservation-row {
  font-weight: bold;
}
</style>
