<template>
  <div>
    <div class="parameters">
      <SpotSearchParameters />
    </div>
    <a
      @click="showAdvancedSearch = !showAdvancedSearch"
      href="javascript:void(0);"
      role="button"
      class="showFilter float-right"
    >
      Advanced Search
      <i class="fas fa-chevron-down" v-if="!this.showAdvancedSearch"></i>
      <i class="fas fa-chevron-up" v-if="this.showAdvancedSearch"></i>
      {{
        selectedAttributes.length > 0
          ? "(" + selectedAttributes.length + ")"
          : ""
      }}
    </a>
    <hr class="px-0 mt-4 mb-1" />
    <SpotAttributeFilters v-if="this.showAdvancedSearch" />
    <div class="sticky-top-dates p-2 mt-1 mb-2">
      <div class="form-row justify-content-center">
        <div
          class="col-12 col-sm-12 col-md-6 form-group text-left mx-0 mt-0 mb-3"
        >
          <DateInput
            vid="arrivalDate"
            rules="required|isDate|arrivalDate"
            name="Arrival Date"
            id="startDate"
            :dateDisabledFn="minArrivalDate"
            v-model="startDate"
          />
        </div>
        <div
          class="col-12 col-sm-12 col-md-6 form-group text-left mx-0 mt-0 mb-3"
        >
          <DateInput
            vid="departureDate"
            rules="required|isDate"
            name="Departure Date"
            id="endDate"
            :dateDisabledFn="minDepartureDate"
            :initialDate="initialDate"
            v-model="endDate"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 mt-1" @click="noStartEndDateAlert">
          <input
            type="checkbox"
            class="mr-2"
            id="onlyShowAvailable"
            v-model="onlyShowAvailable"
            :disabled="!startDate || !endDate"
          />
          <label class="form-check-label" for="onlyShowAvailable"
            >Show only spots that are available</label
          >
        </div>
        <div class="col-lg-6">
          <button
            type="button"
            class="btn btn-primary"
            @click="loadSpots"
            id="Park-search-result-button"
          >
            update search result
          </button>
        </div>
      </div>
    </div>
    <div class="spotResults">
      <SpotResults
        :spots="spots"
        :classifications="classifications"
        :locationName="locationName"
        :locationId="locationId"
        :editView="editView"
        :spotReservationId="spotReservationId"
        :loadingSpots="loadingSpots"
        @loadSpots="loadSpots"
        ref="spotResults"
      />
    </div>
    <b-modal
      id="showAvailableModal"
      title="Show Available Spots"
      no-stacking
      no-close-on-backdrop
      hide-header-close
      no-close-on-esc
      centered
    >
      <p class="my-4">
        Please select Arrival and Departure Date to use this feature.
      </p>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn" variant="primary" @click="ok">Ok</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SpotSearchParameters from "@/components/parkdetails/SpotSearchParameters.vue";
import SpotAttributeFilters from "@/components/parkdetails/SpotAttributeFilters.vue";
import SpotResults from "@/components/parkdetails/SpotResults.vue";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";

export default {
  name: "SpotSearch",
  components: {
    SpotSearchParameters,
    SpotResults,
    DateInput,
    SpotAttributeFilters
  },
  data() {
    return {
      showAdvancedSearch: false
    };
  },
  props: {
    spots: {
      type: Array
    },
    classifications: {
      type: Array
    },
    locationName: {
      type: String
    },
    locationId: {
      type: Number
    },
    editView: {
      type: Boolean,
      default: false
    },
    spotReservationId: {
      type: Number
    },
    loadingSpots: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    minArrivalDate(ymd) {
      return moment().isAfter(ymd, "day");
    },
    minDepartureDate(ymd) {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY")
          .add(-1, "day")
          .isSameOrAfter(ymd, "day");
      } else {
        return moment().isSameOrAfter(ymd, "day");
      }
    },
    loadSpots() {
      this.spotSearchHasBeenClicked = true;
      this.$store.commit("search/setSelectedProductClassifications", null);
      if (this.view == "cal") {
        this.$refs.spotResults?.$refs.spotResultsCalendar?.loadSpots();
      }
      this.$emit("loadSpots");
    },
    noStartEndDateAlert() {
      if (this.startDate && this.endDate) {
        return;
      }

      this.$bvModal.show("showAvailableModal");
    }
  },
  computed: {
    startDate: {
      get() {
        return this.$store.getters["search/startDate"];
      },
      set(val) {
        // if it matches MM/DD/YYYY set, else dont
        if (
          val &&
          val.match(
            /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          )
        )
          this.$store.commit("search/setSearchDate", {
            key: "startDate",
            value: val
          });
      }
    },
    endDate: {
      get() {
        return this.$store.getters["search/endDate"];
      },
      set(val) {
        // if it matches MM/DD/YYYY set, else dont
        if (
          val &&
          val.match(
            /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          )
        )
          this.$store.commit("search/setSearchDate", {
            key: "endDate",
            value: val
          });
      }
    },
    selectedAttributes() {
      const selected = this.$store.getters["search/selectedAttributes"];
      return selected ? selected : [];
    },
    selectedSpotIsDayUse() {
      return this.$store.getters["search/selectedSpotIsDayUse"];
    },
    view() {
      return this.$store.getters["search/searchView"];
    },
    onlyShowAvailable: {
      get() {
        return this.$store.getters["search/onlyShowAvailable"];
      },
      set(val) {
        this.$store.commit("search/setOnlyShowAvailable", val);
      }
    },
    spotSearchHasBeenClicked: {
      get() {
        return this.$store.getters["search/spotSearchHasBeenClicked"];
      },
      set(val) {
        this.$store.commit("search/setSpotSearchHasBeenClicked", val);
      }
    },
    initialDate() {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY").format("YYYY-MM-DD");
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped>
.parameters {
  min-height: 200px;
  padding: 10px;
}
.sticky-top-dates {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
  border: 2px solid #eee;
}
.showFilter {
  color: #1078a8 !important;
}
</style>
