<template>
  <label>
    <input
      type="checkbox"
      :id="id"
      @change="toggleOption"
      :checked="this.selectedSpotTypes.includes(this.id)"
    />
    <i class="icon mr-1 ml-2" :class="icon" />
    {{ name }} Spots
  </label>
</template>

<script>
export default {
  name: "CheckboxParameter",
  props: {
    id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  },
  methods: {
    toggleOption() {
      let spotTypes = this.selectedSpotTypes;
      if (this.selectedSpotTypes.includes(this.id)) {
        spotTypes = this.selectedSpotTypes.filter(item => item !== this.id);
      } else {
        spotTypes.push(this.id);
      }
      this.$store.commit("search/setSelectedSpotTypes", [...spotTypes]);
    }
  },
  computed: {
    selectedSpotTypes() {
      const selectedSpotTypes = this.$store.getters["search/selectedSpotTypes"];
      return !selectedSpotTypes ? [] : selectedSpotTypes;
    }
  }
};
</script>

<style scoped>
.icon {
  font-size: 1.2rem;
  color: #999;
  vertical-align: middle;
}
</style>
