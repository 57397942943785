<template>
  <div class="popup">
    <div class="photo my-2">
      <img
        :src="getFeaturedImageUrl()"
        width="225px"
        height="150px"
        :alt="'spot ' + spot.name + ' featured landscape'"
      />
    </div>
    <p class="site-name">{{ spot.name }}</p>
    <p>{{ spot.area }} - Loop {{ spot.loop }}</p>
    <p
      v-b-popover.hover.left="spot.product.productClassificationDescription"
      title="Class Description"
    >
      {{ spot.product.productClassificationName }}
      <i class="fas fa-question-circle"></i>
    </p>
    <div class="clearfix py-2">
      <div class="attribute-icons float-left">
        <i
          v-for="attribute in getIconAttributes(spot)"
          :key="attribute.id"
          :class="attribute.icon"
          class="pl-1"
        ></i>
      </div>
      <div class="float-right" v-if="maxPeople">
        Max. {{ maxPeople }}
        <img
          :src="require('@/assets/images/icons/people.svg')"
          alt="People Icon"
          height="20px"
          width="20px"
        />
      </div>
    </div>
    <div class="clearfix">
      <div
        class="float-left"
        :class="spot.isSpotAvailable ? 'reserve-btn' : 'btn-min-width'"
      >
        <SpotDetailsModal :spotId="spot.id" :name="spot.name" />
      </div>
      <div v-if="spot.isSpotAvailable" class="float-right reserve-btn">
        <Reserve
          :reservedSpotId="spot.id"
          :locationId="spot.locationId"
          :locationName="spot.locationName"
          :isSpotAvailable="spot.isSpotAvailable"
          :adaRequiredSpot="adaRequiredSpot"
          :isDayUse="isDayUse"
        />
      </div>
      <div v-else class="float-right btn-min-width">
        <button
          class="btn btn-primary d-flex icon-center view-dates"
          type="button"
          @click="switchToCalendar"
        >
          <span class="mr-auto">View Dates</span>
          <i class="ml-1 my-auto fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LocationService from "@/services/LocationService.js";
import Reserve from "@/components/parkdetails/Reserve.vue";
import SpotDetailsModal from "@/components/parkdetails/SpotDetailsModal.vue";

export default {
  name: "SpotMarkerPopup",
  components: {
    Reserve,
    SpotDetailsModal
  },
  props: {
    spot: Object
  },
  methods: {
    getIconAttributes() {
      const locationService = new LocationService(
        this.spot.tenantId,
        this.spot.locationId
      );
      return locationService.getIconAttributesToDisplay(this.spot);
    },
    getFeaturedImageUrl() {
      return this.spot.featuredImageUrl;
    },
    switchToCalendar() {
      this.$store.commit("search/setSearchView", "cal");
    }
  },
  computed: {
    maxPeople() {
      const maxPeople = this.spot.spotAttributes.find(
        x => x.name === "Maximum Number of People"
      );
      return maxPeople ? maxPeople.value : null;
    },
    adaRequiredSpot() {
      const adaRequired = this.spot.spotAttributes.find(
        x => x.name === "ADA Occupant Required"
      );
      return adaRequired ? adaRequired.value === "yes" : false;
    },
    isDayUse() {
      return this.spot.product.bookingTypeId === 2; //Day Use
    }
  }
};
</script>

<style scoped>
.button-unavailable {
  background-color: #1078a8;
  color: #ffffff;
  opacity: 0.5;
  width: 85px;
}
.btn-gray {
  background-color: #eee;
}
.additional-info {
  background-color: #eee;
  color: #999;
  width: 85px;
}
.attribute-icons {
  color: #999;
  font-size: 1rem;
}
.site-name {
  color: #1078a8;
  font-weight: bold;
}
.popup {
  width: 225px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.popup p {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}
.btn-min-width button {
  min-width: 100px;
}
.reserve-btn button {
  min-width: 110px;
}
.icon-center {
  align-items: center;
}
.view-dates {
  background-color: #666666 !important;
  border-color: #666666 !important;
}
</style>
