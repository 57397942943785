<template>
  <button
    @click="showModal"
    :class="
      'btn btn-gray w-100 d-flex center ' + (squareButton ? ' btn-square' : '')
    "
    type="button"
  >
    <div class="m-auto">
      <span>Details</span>
      <i class="fas fa-info-circle ml-2"></i>
    </div>
    <b-modal
      :id="'modal-' + spotId"
      :ref="'modal-' + spotId"
      size="xl"
      header-class="border-0 h-25 pb-0 pl-3 pr-3"
      body-class
      footer-class="border-0"
    >
      <template v-slot:modal-header="{ close }">
        <div class="col-11 p-2">
          <h3 class="spot-detail-header">{{ name }} Details</h3>
        </div>
        <div class="col-1 p-2 float-right">
          <a role="button" @click="close" href="javascript:void(0);">
            Close
            <i class="fas fa-times"></i>
          </a>
        </div>
      </template>
      <div class="item-details" v-if="!!spot">
        <div class="row">
          <div class="col-6">
            <div class="col-12 pl-2">
              <div class="spot-header-line">{{ areaName }}</div>
              <div class="spot-header-line">{{ spot.loop.name }}</div>
              <div class="spot-header-line">
                {{ spot.product.productClassificationName }}
              </div>
            </div>
            <div class="col-12 pt-2 pl-2">
              <div class="area-header">GPS Info:</div>
              <div class="area-description">
                {{ spot.latitude }}, {{ spot.longitude }}
              </div>
              <div class="area-description">
                {{ spot.latitude | convertDMSLat }}
                {{ spot.longitude | convertDMSLong }}
              </div>
              <div class="area-header">Associated Spot Types:</div>
              <div class="area-description">
                {{ spotTypes }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="area-header">Photos:</div>
            <div id="spotPhotos" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators" v-if="spot.images.length == 0">
                <li
                  data-target="#spotPhotos"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  v-for="(photo, index) in spot.images"
                  :key="index"
                  data-target="#spotPhotos"
                  :data-slide-to="index"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  class="carousel-item active"
                  v-if="spot.images.length == 0"
                >
                  <img
                    class="d-block w-100"
                    :src="spot.featuredImageUrl"
                    alt="Slide 0"
                  />
                </div>
                <div
                  v-for="(photo, index) in spot.images"
                  :key="index"
                  class="carousel-item"
                  :class="getActiveImageClass(photo)"
                >
                  <img
                    class="d-block w-100"
                    :src="photo.url"
                    :alt="`Slide ${index + 1}`"
                  />
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#spotPhotos"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#spotPhotos"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        <div class="row pr-0 mr-0 ml-0 pl-0">
          <div class="col-12 p-2">
            <div class="item-detail-header mt-2">Spot Details</div>
          </div>
        </div>
        <div class="row pr-0 mr-0 ml-0 pl-0 no-gutters">
          <div
            class="col-6"
            v-for="(list, listIndex) in spotAttributes"
            :key="listIndex"
          >
            <div
              v-for="(attribute, index) in list"
              :key="attribute.globalAttributeId"
              class="row pr-0 mr-0 ml-0 pl-0 attribute-values no-gutters"
            >
              <div
                class="col-8 font-weight-bold ml-2 pl-1"
                :class="index % 2 == 0 ? '' : 'odd'"
              >
                {{ attribute.name }}
              </div>
              <div
                class="col-3"
                style="text-transform: capitalize"
                :class="index % 2 == 0 ? '' : 'odd'"
              >
                {{ attribute.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="spinner" v-else>
        <span
          class="spinner-border spinner-border-sm my-4"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </button>
</template>

<script>
import LocationService from "@/services/LocationService.js";

export default {
  name: "SpotDetailsModal",
  props: {
    spotId: Number,
    name: String,
    squareButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      spot: null
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    spotTypes() {
      let spotTypeList = this.spot.spotSpotTypes;
      spotTypeList = spotTypeList
        .map(x => x.spotType)
        .map(x => x.name.trim())
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        .join(", ");
      return spotTypeList;
    },
    areaName() {
      return this.spot.area?.name;
    },
    spotAttributes() {
      let attributes = this.spot.attributes;
      attributes = attributes
        .filter(x => x.attributeSelectedOptions.length > 0 || x.value)
        .map(x => {
          return {
            name: x.name,
            value: x.value
              ? x.value
              : x.attributeSelectedOptions
                  .map(x => x.value)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .join(", ")
          };
        })
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      let columnItemCount = Math.ceil(attributes.length / 2);
      return [
        attributes.slice(0, columnItemCount),
        attributes.slice(columnItemCount)
      ];
    }
  },
  methods: {
    getActiveImageClass(photo) {
      if (photo.url == this.spot.featuredImageUrl) {
        return "active";
      }
      return "";
    },
    showModal() {
      this.$refs["modal-" + this.spotId].show();
      this.getSpotDetails();
    },
    async getSpotDetails() {
      const locationService = new LocationService(this.tenantId);
      const response = await locationService.getSpotDetails(this.spotId);
      this.spot = response.spotDetails;
    }
  }
};
</script>

<style scoped>
.item-detail-header {
  font-weight: bold;
  border-bottom: 1px solid black;
}
.attribute-icons {
  color: #999;
  font-size: 0.8rem;
}
.attribute-values {
  text-align: left;
}
.odd {
  background-color: #eee;
}
.spot-detail-header {
  color: #1078a8;
  font-weight: bold !important;
}
.area-header {
  font-weight: bold;
}
.area-description {
  color: #707070;
  font-size: initial;
}
.spot-header-line {
  font-weight: bold;
  font-size: large;
}
.carousel,
.carousel-inner > .carousel-item > img {
  max-height: 700px;
  min-height: 100%;
}
.spinner {
  text-align: center;
}
.btn-gray {
  background-color: #eee;
}
</style>
