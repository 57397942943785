<template>
  <div
    class="d-flex my-2 align-items-center"
    style="background-color: #eee; border-radius: 5px"
  >
    <div
      v-if="(!atWeekStart || adminView) && !loadingSpots"
      style="border-right: 2px solid #ccc"
    >
      <a
        href="javascript:void(0);"
        role="button"
        @click="decreaseWeek"
        aria-label="Previous Week"
      >
        <i class="align-middle fas fa-fw fa-angle-left week"></i>
      </a>
    </div>
    <div v-else style="border-right: 2px solid #ccc">
      <i
        class="align-middle fas fa-fw fa-angle-left week"
        style="color: #ccc"
      ></i>
    </div>
    <div class="text-center week" style="flex: 1">
      {{ weekStart }} - {{ weekEnd }}
    </div>
    <div v-if="!loadingSpots" style="border-left: 2px solid #ccc">
      <a
        href="javascript:void(0);"
        role="button"
        @click="increaseWeek"
        aria-label="Next Week"
      >
        <i class="align-middle fas fa-fw fa-angle-right week"></i>
      </a>
    </div>
    <div v-else style="border-left: 2px solid #ccc">
      <i
        class="align-middle fas fa-fw fa-angle-right week"
        style="color: #ccc"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpotResultsCalendarWeekSelection",
  props: {
    loadingSpots: Boolean,
    adminView: Boolean,
    weekStart: String,
    weekEnd: String,
    atWeekStart: Boolean
  },
  methods: {
    decreaseWeek() {
      this.$emit("decreaseWeek");
    },
    increaseWeek() {
      this.$emit("increaseWeek");
    }
  }
};
</script>

<style scoped>
.week {
  font-size: 1.2rem !important;
}
</style>
