<template>
  <div>
    <a
      class="product-class-item"
      :class="selectedClass"
      role="button"
      href="javascript:void(0);"
      @click="toggleSelect"
    >
      <p v-b-popover.hover.top="classification.description">
        {{ classification.name }}
        <i
          v-for="icon in classification.icons"
          :class="'product-class-item-icon ' + icon.icon"
          :key="icon.id"
        ></i>
        ({{ count }})
      </p>
    </a>
  </div>
</template>

<script>
export default {
  name: "ClassificationItem",
  props: {
    classification: Object,
    count: Number
  },
  computed: {
    selectedClass() {
      if (
        this.selectedProductClassifications.includes(this.classification.id)
      ) {
        return "selected";
      }
      return "";
    },
    selectedProductClassifications() {
      const selectedProductClassifications = this.$store.getters[
        "search/selectedProductClassifications"
      ];
      return !selectedProductClassifications
        ? []
        : selectedProductClassifications;
    }
  },
  methods: {
    toggleSelect() {
      let productClassifications = this.selectedProductClassifications;
      if (
        this.selectedProductClassifications.includes(this.classification.id)
      ) {
        //Don't allow deslecting all
        // if (this.selectedProductClassifications.length == 1) {
        //   this.$bvModal.show("classModal");
        // } else {
        productClassifications = this.selectedProductClassifications.filter(
          item => item !== this.classification.id
        );
        // }
      } else {
        productClassifications.push(this.classification.id);
      }
      this.$store.commit("search/setSelectedProductClassifications", [
        ...productClassifications
      ]);
    }
  }
};
</script>

<style scoped>
.product-class-item {
  margin-right: 0.2rem;
  margin-bottom: 0.3rem;
  padding: 3px;
  line-height: 2;
  border: solid 1px #ccc;
  text-align: center;
  display: block;
  text-decoration: none;
  color: #333;
}

.product-class-item .product-class-item-icon {
  margin: 4px;
}

.selected {
  border: solid 1px #999;
  background-color: #eee;
}
p {
  margin-bottom: 0;
}
</style>
