<template>
  <div>
    <h3 class="results-header mb-0">Search Results</h3>
    <div class="container p-0">
      <div class="d-flex">
        <div class="mr-auto">
          {{ spotsCount }} Spots match your selection(s)
        </div>
        <div class="px-1" :class="listIconClass" @click="viewToggle('list')">
          <a href="javascript:void(0);"><i class="fas fa-list"></i> List</a>
        </div>
        <div class="px-1" :class="calendarIconClass" @click="viewToggle('cal')">
          <a href="javascript:void(0);"
            ><i class="fas fa-calendar-alt"></i> Calendar</a
          >
        </div>
      </div>
      <div class="row m-0">
        <div class="col-12 px-0">
          <hr class="px-0 mt-1 mb-2" />
        </div>
      </div>
      <ClassificationFilters
        :classifications="classifications"
        :classCounts="classCounts"
      />
      <div class="row m-0">
        <div class="col-12 px-0">
          <hr class="px-0 mt-1 mb-1" />
        </div>
      </div>
    </div>

    <div class="results" :class="view === 'list' ? 'results-list' : ''">
      <SpotResultsList
        v-show="view == 'list'"
        :spots="spots"
        :locationName="locationName"
        :editView="editView"
        :loadingSpots="loadingSpots"
      />
      <SpotResultsCalendar
        v-if="view == 'cal'"
        :locationId="locationId"
        :adminView="false"
        :editView="editView"
        :spotReservationId="spotReservationId"
        ref="spotResultsCalendar"
      />
    </div>
  </div>
</template>

<script>
import SpotResultsList from "@/components/parkdetails/SpotResultsList.vue";
import SpotResultsCalendar from "@/components/parkdetails/SpotResultsCalendar.vue";
import ClassificationFilters from "@/components/parkdetails/ClassificationFilters.vue";

export default {
  name: "SpotResults",
  components: {
    SpotResultsList,
    ClassificationFilters,
    SpotResultsCalendar
  },
  data() {
    return {
      isCalInitialized: false
    };
  },
  props: {
    spots: {
      type: Array
    },
    classifications: {
      type: Array
    },
    locationName: {
      type: String
    },
    locationId: {
      type: Number
    },
    editView: {
      type: Boolean,
      default: false
    },
    spotReservationId: {
      type: Number
    },
    loadingSpots: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listIconClass() {
      if (this.view == "list") {
        return "selected-icon";
      } else {
        return "icon";
      }
    },
    calendarIconClass() {
      if (this.view == "cal") {
        return "selected-icon";
      } else {
        return "icon";
      }
    },
    view() {
      return this.$store.getters["search/searchView"];
    },
    spotsCount() {
      return this.$store.getters["search/spotsCount"];
    },
    classCounts() {
      var counts = this.spots.reduce((p, spot) => {
        var id = spot.product.productClassificationId;
        if (!p[id]) {
          p[id] = 0;
        }
        p[id]++;
        return p;
      }, {});
      return counts;
    }
  },
  methods: {
    viewToggle(value) {
      this.$store.commit("search/setSearchView", value);
    }
  },
  watch: {
    view() {
      if (this.view == "cal") {
        this.isCalInitialized = true;
        this.$emit("loadSpots");
      }
    }
  },
  created() {
    if (this.view == "cal") {
      this.isCalInitialized = true;
    }
  }
};
</script>

<style scoped>
.results {
  min-height: 100%;
  max-height: 810px;
}
.results-list {
  overflow-x: hidden;
  overflow-y: auto;
}
.results-header {
  color: #1078a8;
}
.icon {
  color: #757575;
}
.selected-icon {
  color: #1078a8;
}
h3 {
  font-size: medium;
}
</style>
