<template>
  <nav class="navbar search-bar" aria-label="search bar">
    <div class="mr-2">
      <ParkSearch
        mode="header"
        placeholder="Find Another Park..."
        :includeMapBox="true"
        :includeParks="true"
        style="width: 300px"
      />
    </div>
  </nav>
</template>

<script>
import ParkSearch from "@/components/search/ParkSearch.vue";

export default {
  name: "ParkSearchBar",
  components: { ParkSearch }
};
</script>

<style scoped>
.search-bar {
  background-color: #404040;
  box-shadow: inset 0 6px 6px -6px #000000;
}
</style>
