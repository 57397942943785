<template>
  <button
    type="submit"
    @click.stop="reserveClicked"
    class="btn btn-primary w-100 d-flex icon-center"
    :class="squareClass"
    :id="'reserve' + reservedSpotId"
    :disabled="unavailable"
  >
    <div class="m-auto">
      <span>Reserve</span>
      <i class="ml-2 fas fa-arrow-circle-right"></i>
    </div>
    <b-modal
      ref="ada-required-modal"
      size="sm"
      hide-header-close
      header-class="border-0"
      footer-class="border-0"
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <h4>ADA Occupant Required</h4>
        <h5>
          This spot is reservable only by or for persons with disabilities. By
          answering yes to this question, I am attesting that this spot is being
          reserved for a person(s) with disabilities.
        </h5>
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button
          class="btn"
          variant="primary"
          @click="adaRequiredAcknowledged(ok)"
          >Yes</b-button
        >
        <b-button class="btn" variant="" @click="ok">No</b-button>
      </template>
    </b-modal>
    <AdminOverrideAlertModal
      @override="overrideParkClosure"
      @cancel="cancelOverrideParkClosure"
      header="Do you wish to override this park closure?"
      :modalRef="this.modalRef"
    />
  </button>
</template>

<script>
import { mapGetters } from "vuex";
import SpotReservationService from "@/services/SpotReservationService.js";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import AdminOverrideAlertModal from "@/components/admin/AdminOverrideAlertModal.vue";

import moment from "moment";
export default {
  name: "Reserve",
  components: {
    AdminOverrideAlertModal
  },
  props: {
    reservedSpotId: Number,
    locationId: Number,
    locationName: String,
    adminView: Boolean,
    walkInReservation: { type: Boolean, default: false },
    editView: { type: Boolean, default: false },
    spotReservationId: { type: Number, default: null },
    squareClass: { type: String, default: "" },
    spotAvailabilities: Array,
    calendarView: { type: Boolean, default: false },
    isDayUse: { type: Boolean, default: false },
    isSpotAvailable: Boolean,
    adaRequiredSpot: { type: Boolean, default: false },
    unavailable: { type: Boolean, default: false }
  },
  data() {
    return {
      adaRequirementAcknowledged: false,
      overrideClosure: false
    };
  },
  computed: {
    ...mapGetters("search", ["startDate", "endDate"]),
    ...mapGetters("tenant", ["tenantId"]),
    existingStartDate() {
      return this.$store.getters["transaction/reservation"].oldStartDate;
    },
    existingEndDate() {
      return this.$store.getters["transaction/reservation"].oldEndDate;
    },
    existingSpotId() {
      return this.$store.getters["transaction/reservation"].oldSpotId;
    },
    lockCode() {
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      if (claimData == null) {
        return null;
      }
      return claimData.lockCode;
    },
    containsClosure() {
      var selectedDates = this.spotAvailabilities.filter(
        x =>
          x.availabilityDate >= moment(this.startDate).format("YYYY-MM-DD") &&
          x.availabilityDate <= moment(this.endDate).format("YYYY-MM-DD")
      );
      return selectedDates.some(x => x.availabilityCode == "ClosedOverridable");
    },
    modalRef() {
      return `closure-override-modal-${this.reservedSpotId}`;
    },
    selectedSpotIsDayUse() {
      return this.$store.getters["search/selectedSpotIsDayUse"];
    },
    customerId() {
      const reservation = this.$store.getters["transaction/reservation"];
      if (reservation == null) {
        return null;
      }
      return reservation.customerId;
    }
  },
  methods: {
    adaRequiredModal() {
      this.$refs["ada-required-modal"].show();
    },
    adaRequiredAcknowledged(ok) {
      ok();
      this.adaRequirementAcknowledged = true;
      this.reserveClicked();
    },
    cancelOverrideParkClosure() {
      this.$bvModal.hide(this.modalRef);
    },
    overrideParkClosure() {
      this.$bvModal.hide(this.modalRef);
      this.overrideClosure = true;
      this.reserveClicked();
    },
    reserveClicked() {
      if (this.adminView && this.containsClosure && !this.overrideClosure) {
        this.$bvModal.show(this.modalRef);
        return;
      }
      if (this.adaRequiredSpot && !this.adaRequirementAcknowledged) {
        this.adaRequiredModal();
        return;
      }

      this.$store.commit("search/setSelectedSpotIsDayUse", this.isDayUse);
      if (this.isSpotAvailable === false && this.calendarView === false) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Spot is not available for the selected dates.",
          layer: `${this.adminView === true ? "admin" : "detail"}`
        });
      } else if (this.calendarView === true) {
        if (this.startDate && this.endDate) {
          let datesSelected = this.spotAvailabilities.filter(x =>
            moment(x.availabilityDate, "YYYY/MM/DD").isBetween(
              moment(this.startDate, "MM/DD/YYYY"),
              moment(this.endDate, "MM/DD/YYYY").add(this.isDayUse ? 0 : -1),
              "day",
              "[]"
            )
          );

          if (datesSelected.length == 0) {
            datesSelected = this.spotAvailabilities.filter(x =>
              moment(x.availabilityDate, "YYYY-MM-DD").isSame(
                this.startDate,
                "MM/DD/YYYY"
              )
            );
          }

          if (
            datesSelected.every(
              x =>
                x.availabilityCode === "Available" ||
                (x.availabilityCode === "ClosedOverridable" &&
                  this.adminView) ||
                (x.availabilityCode === "Walkin" && this.adminView)
            )
          ) {
            this.reserve(this.reservedSpotId);
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Spot is not available for the selected dates.",
              layer: `${this.adminView === true ? "admin" : "detail"}`
            });
          }
        }
      } else {
        this.reserve(this.reservedSpotId);
      }
    },
    cancelAndReserve() {
      const spotReservationService = new SpotReservationService(this.tenantId);
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      spotReservationService
        .releaseSpotClaim(claimData.spotId, claimData.lockCode)
        .then(response => {
          if (response.statusCode === "Success") {
            let reservation = this.$store.getters["transaction/reservation"];
            let reservationId = reservation?.id;
            this.$store.dispatch("transaction/clearReservation");
            reservation = this.$store.getters["transaction/reservation"];
            reservation.id = reservationId;
            this.$store.commit("setReservation", reservation);
            this.existingSpotClaimed = false;
            this.reserve(this.reserveSpotId);
          }
        });
    },
    async reserve(spotId) {
      this.error = "";
      if (!this.reservationDatesAreValid()) return;
      this.$store.commit("auth/setLoading", true);
      if (this.adminView) {
        const spotReservationService = new AdminSpotReservationService(
          this.tenantId
        );
        const response = await spotReservationService.claimSpot(
          spotId,
          this.startDate,
          this.endDate,
          this.lockCode,
          this.spotReservationId,
          this.customerId,
          this.walkInReservation
        );
        if (response && response.statusCode === "Success") {
          this.$store.dispatch("transaction/setSpotClaimInfo", {
            locationName: this.locationName,
            locationId: this.locationId,
            spotId: spotId,
            lockCode: response.data.lockCode,
            lockedUntil: response.data.lockedUntil,
            walkInReservation: this.walkInReservation,
            adaRequirementAcknowledged: this.adaRequirementAcknowledged,
            overrideClosure: this.overrideClosure
          });
          this.$store.commit("transaction/setReservationInProgress", true);
          this.$store.commit("transaction/setReservationDates", {
            startDate: this.startDate,
            endDate: this.endDate
          });
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-info",
            message:
              "Your reservation is being held for 15 minutes while you complete your purchase",
            layer: "admin"
          });
          this.routingOnReserve();
        } else if (response) {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: response.messages[0],
            layer: "admin"
          });
          this.$store.commit("auth/setLoading", false);
        }
      } else {
        const spotReservationService = new SpotReservationService(
          this.tenantId
        );
        const response = await spotReservationService.claimSpot(
          spotId,
          this.startDate,
          this.endDate,
          this.lockCode,
          this.spotReservationId,
          this.customerId
        );
        if (response && response.statusCode === "Success") {
          this.$store.dispatch("transaction/setSpotClaimInfo", {
            locationName: this.locationName,
            locationId: this.locationId,
            spotId: spotId,
            lockCode: response.data.lockCode,
            lockedUntil: response.data.lockedUntil,
            adaRequirementAcknowledged: this.adaRequirementAcknowledged
          });
          this.$store.commit("transaction/setReservationInProgress", true);
          this.$store.commit("transaction/setReservationDates", {
            startDate: this.startDate,
            endDate: this.endDate
          });
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-info",
            message:
              "Your reservation is being held for 15 minutes while you complete your purchase",
            layer: "public"
          });
          this.$store.commit("auth/setLoading", false);
          this.routingOnReserve();
        } else if (response) {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: response.messages[0],
            layer: "detail"
          });
          this.$store.commit("auth/setLoading", false);
        }
      }
    },
    routingOnReserve() {
      window.scrollTo(0, 0);
      if (this.editView) {
        this.$router.push("/reservation-edit-occupant").catch(() => {});
      } else if (!this.adminView) {
        this.$router.push("/create-reservation").catch(() => {});
      } else if (
        this.$router.currentRoute.name === "AdminReservationEditSpot"
      ) {
        this.$router.push("/admin/reservation-edit-occupant").catch(() => {});
      } else {
        this.$router.push("/admin/reservation-add-occupant").catch(() => {});
      }
    },
    checkIfExistingSpotClaimed() {
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      if (claimData.lockedUntil != null) {
        var currentDate = new Date();
        var lockedUntilDate = new Date(claimData.lockedUntil);
        if (currentDate < lockedUntilDate) {
          this.existingSpotClaimed = true;
        } else {
          this.existingSpotClaimed = false;
        }
      }
    },
    reservationDatesAreValid() {
      if (!this.startDateIsValid() || !this.endDateIsValid()) {
        return false;
      }
      return true;
    },
    startDateIsValid() {
      if (!this.startDate) return false;
      return (
        moment().isSameOrBefore(moment(this.startDate, "MM-DD-YYYY"), "day") ||
        this.adminView
      );
    },
    endDateIsValid() {
      if (!this.endDate || !this.startDate) return false;
      const endDateIsValid =
        (!this.selectedSpotIsDayUse &&
          moment(this.startDate, "MM-DD-YYYY").isBefore(
            moment(this.endDate, "MM-DD-YYYY"),
            "day"
          )) ||
        this.selectedSpotIsDayUse;

      if (!endDateIsValid) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message:
            "Departure date must be greater than arrival date for overnight spots.",
          layer: `${this.adminView === true ? "admin" : "detail"}`
        });
      }

      return endDateIsValid;
    }
  }
};
</script>

<style scoped>
.icon-center {
  align-items: center;
}
</style>
