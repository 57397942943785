<template>
  <div v-if="classifications">
    <div class="row m-0">
      <div
        v-for="classification in classifications"
        :key="classification.classification.id"
        class="col-4 px-0"
      >
        <ClassificationItem
          :classification="classification.classification"
          :count="getCount(classification.classification.id)"
        />
      </div>
    </div>
    <!-- <b-modal id="classModal" ok-only title="Class Required">
      <p class="mt-4">Please select at least one class.</p>
    </b-modal> -->
  </div>
</template>

<script>
import ClassificationItem from "@/components/parkdetails/ClassificationItem.vue";

export default {
  name: "ClassificationFilters",
  components: {
    ClassificationItem
  },
  props: {
    classifications: {
      type: Array
    },
    classCounts: {
      type: Object
    }
  },
  computed: {
    selectedProductClassifications() {
      const selectedProductClassifications = this.$store.getters[
        "search/selectedProductClassifications"
      ];
      return !selectedProductClassifications
        ? []
        : selectedProductClassifications;
    }
  },
  methods: {
    getCount(id) {
      let result = this.classCounts[id];
      if (!result) {
        return 0;
      }
      return result;
    }
  }
};
</script>

<style scoped>
.selected {
  border: solid 1px #999;
  background-color: #eee;
}
</style>
