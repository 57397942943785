<template>
  <div>
    <div v-if="!loadingSpots">
      <div v-for="spot in spotsToShow" :key="spot.id">
        <SpotResultsListItem
          :spot="spot"
          @selected="handleSpotSelected"
          :locationName="locationName"
          :editView="editView"
        />
      </div>
      <div
        class="m-2"
        v-if="spotsToShow.length == 0 && this.spotSearchHasBeenClicked"
      >
        <p>
          No site is available for your search criteria. Please change your
          search criteria to see other availabilities
        </p>
      </div>
      <infinite-loading @infinite="infiniteHandler" :identifier="identifier">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
    <div class="items" v-else>
      <span
        class="spinner-border spinner-border-sm my-4"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import SpotResultsListItem from "@/components/parkdetails/SpotResultsListItem.vue";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "SpotResultsList",
  components: {
    SpotResultsListItem,
    InfiniteLoading
  },
  data() {
    return {
      spotsToShow: [],
      identifier: 1,
      spotIndex: 0
    };
  },
  props: {
    spots: Array,
    locationName: String,
    editView: { type: Boolean, default: false },
    loadingSpots: { type: Boolean, default: false }
  },
  methods: {
    handleSpotSelected(spotId) {
      this.$store.commit("search/setSelectedSpotId", spotId);
    },
    infiniteHandler($state) {
      this.addSelectedSpotToList();
      const currentNumberOfSpots = this.spotsToShow.length;
      for (
        this.spotIndex;
        this.spotIndex < currentNumberOfSpots + 20 &&
        this.spotIndex < this.spots.length;
        this.spotIndex++
      ) {
        if (
          this.spots[this.spotIndex].id != this.selectedSpotId &&
          this.spotsToShow.find(n => n.id == this.spots[this.spotIndex].id) ==
            null
        ) {
          this.spotsToShow.push(this.spots[this.spotIndex]);
        }
      }

      if (this.spotsToShow.length == this.spots.length) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },
    addSelectedSpotToList() {
      //Add the selected spot to the list of spots displayed
      const spotId = this.selectedSpotId;
      if (spotId == null) {
        return;
      }

      const spot = this.spots.find(n => n.id == spotId);
      if (spot != null && !this.spotsToShow.some(x => x.id == spot.id)) {
        this.spotsToShow.unshift(spot);
      }
    }
  },
  computed: {
    selectedSpotId() {
      let spotId = this.$store.getters["search/selectedSpotId"];
      return spotId;
    },
    spotSearchHasBeenClicked() {
      return this.$store.getters["search/spotSearchHasBeenClicked"];
    }
  },
  watch: {
    spots() {
      this.spotsToShow = []; //this.spots.slice(0, 20);
      this.identifier++;
      this.spotIndex = 0;
    },
    selectedSpotId() {
      this.addSelectedSpotToList();
    }
  }
};
</script>

<style scoped>
.items {
  text-align: center;
}
</style>
