<template>
  <div>
    <SpotResultsCalendarWeekSelection
      :adminView="adminView"
      :loadingSpots="loadingSpots"
      :atWeekStart="atWeekStart"
      :weekStart="weekStart"
      :weekEnd="weekEnd"
      @decreaseWeek="decreaseWeek"
      @increaseWeek="increaseWeek"
      v-if="!adminView"
    />
    <div
      :class="adminView ? 'admin-cal-items' : 'cal-items'"
      v-if="!loadingSpots"
    >
      <div v-if="adminView && firstSpot" class="cal-dates">
        <SpotResultsCalendarWeekSelection
          :adminView="adminView"
          :loadingSpots="loadingSpots"
          :atWeekStart="atWeekStart"
          :weekStart="weekStart"
          :weekEnd="weekEnd"
          @decreaseWeek="decreaseWeek"
          @increaseWeek="increaseWeek"
        />
        <div class="d-flex flex-wrap admin-calendar-legend" v-if="adminView">
          <div class="text-center cal-legend-item">
            <i class="far fa-fw fa-check-circle text-primary font-thirty"></i>
            <br />Available
          </div>
          <div class="text-center cal-legend-item">
            <i class="far fa-calendar-check font-thirty"></i>
            <br />Reserved
          </div>
          <div class="text-center cal-legend-item">
            <i class="fas fa-font text-primary font-thirty"></i>
            <br />Allowed with Available
          </div>
          <div class="text-center cal-legend-item">
            <i class="fas fa-fw fa-check-circle text-primary font-thirty"></i>
            <br />Selected
          </div>
          <div class="text-center cal-legend-item">
            <i class="fas fa-times font-thirty"></i>
            <br />Park Closed
          </div>
          <div class="text-center cal-legend-item">
            <i class="far fa-times-circle font-thirty"></i>
            <br />Park Closed (Overridable)
          </div>
          <div class="text-center cal-legend-item">
            <i class="fas fa-fw fa-ban font-thirty"></i>
            <br />Unavailable
          </div>
          <div class="text-center cal-legend-item">
            <div class="fa-stack">
              <i
                class="far fa-circle fa-stack-1x walk-in-circle text-primary"
              ></i>
              <i
                class="fas fa-walking fa-stack-1x small-walking text-primary"
              ></i>
            </div>
            <br />Walk-In
          </div>
        </div>
        <div class="row mt-2">
          <div class="offset-lg-3"></div>
          <div class="col-lg-7 d-flex flex-row">
            <div
              v-for="(avail, spotIndex) in firstSpot.spotAvailabilities"
              :key="spotIndex"
              class="text-center day"
            >
              <div class="date-day">{{ week[spotIndex].day }}</div>
              <div class="date-num pt-2">{{ week[spotIndex].date }}</div>
            </div>
          </div>
          <div class="offset-lg-2"></div>
        </div>
      </div>
      <div
        v-for="(spot, index) in spotsToShow"
        :key="spot.id"
        :class="adminView ? 'admin-cal-item' : ''"
      >
        <AdminSpotResultsCalendarItem
          :spot="spot"
          :week="week"
          :spotAvailabilities="spot.spotAvailabilities"
          :checkedDates="checkedDates"
          @onSelectDate="selectDate"
          :walkInReservation="walkInReservation"
          :editView="editView"
          :spotReservationId="spotReservationId"
          :calItemIndex="index"
          v-if="adminView"
        />
        <SpotResultsCalendarItem
          :spot="spot"
          :week="week"
          :spotAvailabilities="spot.spotAvailabilities"
          :checkedDates="checkedDates"
          @onSelectDate="selectDate"
          :walkInReservation="walkInReservation"
          :editView="editView"
          :spotReservationId="spotReservationId"
          v-else
        />
      </div>
      <div class="m-4" v-if="spotsToShow.length == 0">
        <p>
          No site is available for your search criteria. Please change your
          search criteria to see other availabilities
        </p>
      </div>
      <infinite-loading @infinite="infiniteHandler" :identifier="identifier">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
    <div class="cal-items" v-else>
      <span
        class="spinner-border spinner-border-sm mt-4"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div class="d-flex flex-wrap text-muted calendar-legend" v-if="!adminView">
      <div class="text-center cal-legend-item">
        <i class="far fa-fw fa-check-circle text-primary"></i>
        <br />Available
      </div>
      <div class="text-center cal-legend-item">
        <i class="far fa-calendar-check"></i>
        <br />Reserved
      </div>
      <div class="text-center cal-legend-item" v-if="showAllowedWithAvailable">
        <i class="fas fa-font"></i>
        <br />Allowed with Available
      </div>
      <div class="text-center cal-legend-item">
        <i class="fas fa-fw fa-check-circle text-primary"></i>
        <br />Selected
      </div>
      <div class="text-center cal-legend-item">
        <i class="fas fa-walking"></i>
        <br />Walk-In
      </div>
      <div class="text-center cal-legend-item">
        <i class="fas fa-fw fa-ban"></i>
        <br />Unavailable
      </div>
    </div>
  </div>
</template>

<style scoped>
.week {
  font-size: 1.2rem;
}
.cal-items {
  height: 710px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
}
.admin-cal-items {
  text-align: center;
}
.calendar-legend {
  background: #eee;
  border: 1px solid #ccc;
  padding: 0.5em;
}
.admin-calendar-legend {
  background-color: #ffffff;
  background: linear-gradient(#eee 1%, #ffffff);
  padding: 0.5em;
  border-top: 2px solid #ccc;
  font-weight: bold;
  font-size: larger;
}
.text-center {
  color: rgb(73, 80, 87) !important;
}
.cal-legend-item {
  flex: 1;
}
.day {
  flex: 1;
  font-weight: bold;
  font-size: larger;
}
.cal-dates {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
  height: 6%;
}
.date-num {
  font-size: 16px;
}
.date-day {
  height: 20%;
}
.small-walking {
  font-size: 20px;
}
.walk-in-circle {
  font-size: 33px;
}
.font-thirty {
  font-size: 30px;
  color: #ccc;
}
.admin-cal-item:nth-of-type(odd) {
  background: #f4f7f9;
}
.admin-cal-item:nth-of-type(even) {
  background: #ffffff;
}
@media (max-width: 600px) {
  .cal-legend-item {
    flex: 1 0 33%;
  }
}
</style>

<script>
import SpotResultsCalendarItem from "@/components/parkdetails/SpotResultsCalendarItem.vue";
import SpotResultsCalendarWeekSelection from "@/components/parkdetails/SpotResultsCalendarWeekSelection.vue";
import AdminSpotResultsCalendarItem from "@/components/admin/AdminSpotResultsCalendarItem.vue";
import LocationService from "@/services/LocationService.js";
import moment from "moment";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "SpotResultsCalendar",
  props: {
    locationId: Number,
    adminView: Boolean,
    walkInReservation: { type: Boolean, default: false },
    spotReservationId: { type: Number, default: null },
    editView: { type: Boolean, default: false }
  },
  data() {
    return {
      calSearchResults: [],
      spotsToShow: [],
      weekStart: "",
      weekEnd: "",
      week: [],
      atWeekStart: true,
      checkedDates: [],
      unavailableDates: [],
      loadingSpots: false,
      identifier: 1,
      spotIndex: 0
    };
  },
  computed: {
    changesRequiringSpotReload() {
      return `${this.selectedProductClassifications}|${JSON.stringify(
        this.selectedAttributes
      )}|${this.selectedSpotTypes}|${this.selectedLoopId}|${
        this.onlyShowAvailable
      }|${this.selectedSpotName}`;
    },
    classCounts() {
      if (!this.calSearchResults) {
        return {};
      }

      var counts = this.calSearchResults.reduce((p, spot) => {
        var id = spot.product.productClassificationId;
        if (!p[id]) {
          p[id] = 0;
        }
        p[id]++;
        return p;
      }, {});
      return counts;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    startDate() {
      return this.$store.getters["search/startDate"];
    },
    endDate() {
      return this.$store.getters["search/endDate"];
    },
    selectedSpotTypes() {
      return this.$store.getters["search/selectedSpotTypes"];
    },
    selectedProductClassifications() {
      return this.$store.getters["search/selectedProductClassifications"];
    },
    selectedAttributes() {
      return this.$store.getters["search/selectedAttributes"];
    },
    selectedSpotId() {
      return this.$store.getters["search/selectedSpotId"];
    },
    selectedLoopId() {
      return this.$store.getters["search/selectedLoopId"];
    },
    selectedSpotName() {
      return this.$store.getters["search/selectedSpotName"];
    },
    onlyShowAvailable() {
      return this.$store.getters["search/onlyShowAvailable"];
    },
    view() {
      return this.$store.getters["search/searchView"];
    },
    firstSpot() {
      return this.spotsToShow.length > 0 ? this.spotsToShow[0] : null;
    },
    lockCode() {
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      if (claimData == null) {
        return null;
      }
      return claimData.lockCode;
    },
    customerId() {
      const reservation = this.$store.getters["transaction/reservation"];
      if (reservation == null) {
        return null;
      }
      return reservation.customerId;
    },
    isFiltered() {
      return (
        this.selectedSpotTypes?.length > 0 ||
        this.selectedProductClassifications?.length > 0 ||
        this.selectedAttributes?.length > 0 ||
        this.selectedLoopId != null ||
        this.selectedSpotName != null ||
        this.onlyShowAvailable
      );
    },
    isDayUse() {
      const reservation = this.$store.getters["transaction/reservation"];
      if (reservation == null) {
        return null;
      }
      return reservation.bookingType === "Day Use";
    },
    showAllowedWithAvailable() {
      let showIcon = false;
      for (let i = 0; i < this.spotsToShow.length; i++) {
        let spot = this.spotsToShow[i];
        if (!showIcon) {
          for (let j = 0; j < spot.spotAvailabilities.length; j++) {
            let avail = spot.spotAvailabilities[j];
            if (avail.availableAfterMaxArrival) {
              showIcon = true;
              break;
            }
          }
        } else {
          break;
        }
      }
      return showIcon;
    }
  },
  watch: {
    locationId() {
      this.$emit("loadingPark", true);
      this.loadSpots();
    },
    startDate() {
      if (
        moment(this.startDate, "MM-DD-YYYY").isBefore(
          moment(this.weekStart, "MMM D, YYYY"),
          "day"
        ) ||
        moment(this.startDate, "MM-DD-YYYY").isAfter(
          moment(this.weekEnd, "MMM D, YYYY"),
          "day"
        )
      ) {
        this.getDateBoundaries();
        this.getWeek();
        this.loadSpots();
      }
    },
    weekStart() {
      this.getWeek();
    },
    changesRequiringSpotReload() {
      if (this.adminView) {
        this.loadSpots();
      }
    },
    selectedProductClassifications(newValue) {
      if (!this.adminView && newValue) {
        this.loadSpots();
      }
    },
    selectedSpotId() {
      this.addSelectedSpotToList();
      const tempSpotId = this.selectedSpotId;
      const tempStartDate = this.startDate;
      const tempEndDate = this.endDate;
      const tempView = this.view;
      if (
        tempSpotId !== null &&
        tempView === "list" &&
        tempStartDate !== null &&
        !this.adminView
      ) {
        if (
          this.unavailableDates.filter(
            x => x.date === tempStartDate && x.spotId === tempSpotId
          ).length === 0
        ) {
          this.selectDate({ spotId: tempSpotId, date: tempStartDate });
          if (tempEndDate !== null) {
            this.selectDate({ spotId: tempSpotId, date: tempEndDate });
          }
        }
      }
    }
  },
  mounted() {
    this.$emit("loadingPark", true);
    this.getDateBoundaries();
    this.getWeek();
    this.loadSpots();
    this.initialize();
  },
  methods: {
    infiniteHandler($state) {
      this.addSelectedSpotToList();
      const currentNumberOfSpots = this.spotsToShow.length;

      for (
        this.spotIndex;
        this.spotIndex < currentNumberOfSpots + 20 &&
        this.spotIndex < this.calSearchResults.length;
        this.spotIndex++
      ) {
        if (
          this.calSearchResults[this.spotIndex].id != this.selectedSpotId &&
          this.spotsToShow.find(
            n => n.id == this.calSearchResults[this.spotIndex].id
          ) == null
        ) {
          this.spotsToShow.push(this.calSearchResults[this.spotIndex]);
        }
      }

      if (this.spotsToShow.length == this.calSearchResults.length) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },
    addSelectedSpotToList() {
      //Add the selected spot to the list of spots displayed
      const spotId = this.selectedSpotId;
      if (spotId == null) {
        return;
      }

      const spot = this.calSearchResults.find(n => n.id == spotId);
      if (spot != null && !this.spotsToShow.some(x => x.id == spot.id)) {
        this.spotsToShow.unshift(spot);
      }
    },
    async loadSpots() {
      this.loadingSpots = true;
      this.spotIndex = 0;
      this.spotsToShow = [];
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      const searchParams = {
        locationId: this.locationId,
        startDate:
          this.weekStart === ""
            ? this.adminView
              ? moment()
                  .subtract(3, "days")
                  .format("L")
              : moment().format("L")
            : moment(this.weekStart, "MMM D, YYYY").format("L"),
        endDate:
          this.weekEnd === ""
            ? this.adminView
              ? moment()
                  .add(10, "days")
                  .format("L")
              : moment()
                  .add(13, "days")
                  .format("L")
            : moment(this.weekEnd, "MMM D, YYYY").format("L"),
        includeDayAvailability: true,
        lockCode: this.lockCode,
        adminView: this.adminView,
        spotReservationId: this.spotReservationId,
        customerId: this.customerId,
        selectedSpotTypes: this.selectedSpotTypes,
        selectedProductClassifications: this.selectedProductClassifications,
        selectedAttributes: this.selectedAttributes,
        selectedSpotId: this.selectedSpotId,
        loopId: this.selectedLoopId != "null" ? this.selectedLoopId : null,
        spotName:
          this.selectedSpotName != "null" ? this.selectedSpotName : null,
        onlyShowAvailable: this.onlyShowAvailable,
        arrivalDate: this.startDate,
        departureDate: this.endDate
      };
      locationService.spotSearchForLocation(searchParams).then(response => {
        this.calSearchResults = response.spots ? response.spots : [];
        if (this.isFiltered) {
          this.calSearchResults.forEach(x => (x.isFilteredResult = true));
        }
        this.$emit(
          "getCount",
          this.calSearchResults ? this.calSearchResults.length : 0
        );
        this.$store.commit(
          "search/setSpotsCount",
          this.calSearchResults ? this.calSearchResults.length : 0
        );
        this.$emit("updateClassCounts", this.classCounts);
        this.getUnavailableDates();
        this.loadingSpots = false;
        this.$emit("loadingPark", false);
      });
    },
    getDateBoundaries() {
      this.weekStart = this.startDate
        ? moment(this.startDate, "MM-DD-YYYY").format("ll")
        : this.adminView
        ? moment()
            .subtract(3, "days")
            .format("ll")
        : moment().format("ll");
      this.weekEnd = moment(this.weekStart, "MMM D, YYYY")
        .add(13, "days")
        .format("ll");
      const tempWeekStart = this.adminView
        ? moment().subtract(3, "days")
        : moment();
      this.atWeekStart = moment(this.weekStart, "MMM D, YYYY").isSame(
        tempWeekStart,
        "day"
      );
    },
    increaseWeek() {
      this.loadingSpots = true;
      if (this.spotLoadingTimeout != null) {
        clearTimeout(this.spotLoadingTimeout);
      }
      this.weekStart = moment(this.weekStart, "MMM D, YYYY")
        .add(14, "days")
        .format("ll");
      this.weekEnd = moment(this.weekEnd, "MMM D, YYYY")
        .add(14, "days")
        .format("ll");
      this.atWeekStart = false;
      this.spotLoadingTimeout = setTimeout(() => {
        this.loadSpots();
      }, 500);
    },
    decreaseWeek() {
      this.loadingSpots = true;
      if (this.spotLoadingTimeout != null) {
        clearTimeout(this.spotLoadingTimeout);
      }
      let tempWeekStart = moment(this.weekStart, "MMM D, YYYY")
        .subtract(14, "days")
        .format("ll");
      if (
        moment().isSameOrAfter(moment(tempWeekStart, "MMM D, YYYY"), "day") &&
        !this.adminView
      ) {
        this.weekStart = moment().format("ll");
        this.weekEnd = moment()
          .add(13, "days")
          .format("ll");
        this.atWeekStart = true;
      } else {
        this.weekStart = tempWeekStart;
        this.weekEnd = moment(this.weekEnd, "MMM D, YYYY")
          .subtract(14, "days")
          .format("ll");
      }
      this.spotLoadingTimeout = setTimeout(() => {
        this.loadSpots();
      }, 500);
    },
    getWeek() {
      this.week = [];
      for (let i = 0; i < 14; i++) {
        if (i === 0) {
          this.week.push({
            day: moment(this.weekStart, "MMM D, YYYY").format("ddd"),
            date: moment(this.weekStart, "MMM D, YYYY").date()
          });
        } else {
          this.week.push({
            day: moment(this.weekStart, "MMM D, YYYY")
              .add(i, "days")
              .format("ddd"),
            date: moment(this.weekStart, "MMM D, YYYY")
              .add(i, "days")
              .date()
          });
        }
      }
    },
    getUnavailableDates() {
      let dates = [];
      if (this.spotsToShow) {
        this.spotsToShow.forEach(spot => {
          let unavailable = spot.spotAvailabilities.filter(
            x =>
              x.availabilityCode !== "Available" &&
              (x.availabilityCode !== "ClosedOverridable" || !this.adminView)
          );
          let newDate = unavailable.map(x => {
            return {
              spotId: spot.id,
              date: moment(x.availabilityDate).format("L")
            };
          });
          dates = [...dates, ...newDate];
        });
      }
      this.unavailableDates = dates;
    },
    initialize() {
      if (
        this.startDate &&
        this.endDate &&
        this.selectedSpotId &&
        (this.adminView || this.editView)
      ) {
        this.selectDate({
          spotId: this.selectedSpotId,
          date: this.startDate,
          isDayUse: this.isDayUse && this.startDate === this.endDate
        });
        if (!this.isDayUse || this.startDate !== this.endDate) {
          this.selectDate({
            spotId: this.selectedSpotId,
            date: this.endDate,
            isDayUse: false
          });
        }
      }
    },
    selectDate(options) {
      let tempCheckedDates = this.checkedDates;
      let tempUnavailableDates = this.unavailableDates;
      let tempStartDate = this.startDate;
      let tempEndDate = this.endDate;
      let tempSelectedSpotId = this.selectedSpotId;
      if (tempCheckedDates.length === 0) {
        // if checked dates is empty
        // initialize array with start date and select spot id
        tempCheckedDates = [options];
        tempStartDate = options.date;
        if (options.isDayUse) {
          tempEndDate = options.date;
        }
        tempSelectedSpotId = options.spotId;
      } else if (
        // if checked dates already has the date selected
        // remove it from the array and move the start and end date accordingly
        tempCheckedDates.filter(
          x =>
            x.spotId === options.spotId &&
            moment(x.date, "MM-DD-YYYY").isSame(
              moment(options.date, "MM-DD-YYYY"),
              "day"
            )
        ).length > 0
      ) {
        if (tempEndDate !== null) {
          // if the end date is set and the date picked is between the start and end date, there will be a gap
          // need to reassess
          // set new end date to one clicked
          if (
            moment(options.date, "MM-DD-YYYY").isBetween(
              moment(tempStartDate, "MM-DD-YYYY"),
              moment(tempEndDate, "MM-DD-YYYY"),
              "day"
            )
          ) {
            let newDates = [];
            let newEndDate = options.date;
            for (
              let day = moment(tempStartDate, "MM-DD-YYYY");
              day.isSameOrBefore(moment(newEndDate, "MM-DD-YYYY"), "day");
              day.add(1, "days")
            ) {
              newDates.push({ spotId: options.spotId, date: day.format("L") });
            }
            tempCheckedDates = newDates;
            tempEndDate = newEndDate;
            // if the day is the same as the start date
            // remove the start date and set it to the next day, if possible
          } else if (
            moment(options.date, "MM-DD-YYYY").isSame(
              moment(tempStartDate, "MM-DD-YYYY"),
              "day"
            )
          ) {
            // get dates that dont include start date
            let newDates = tempCheckedDates.filter(
              x =>
                !moment(x.date, "MM-DD-YYYY").isSame(
                  moment(options.date, "MM-DD-YYYY"),
                  "day"
                )
            );
            // if that was the last date
            if (newDates.length === 0) {
              tempStartDate = null;
              tempEndDate = null;
              tempCheckedDates = [];
              // if there's only one date
            } else if (newDates.length === 1) {
              tempStartDate = moment(options.date, "MM-DD-YYYY")
                .add(1, "days")
                .format("L");
              tempEndDate = options.isDayUse ? tempStartDate : null;
              tempCheckedDates = [
                { spotId: options.spotId, date: tempStartDate }
              ];
              // if there's more than one date
            } else {
              tempStartDate = moment(options.date, "MM-DD-YYYY")
                .add(1, "days")
                .format("L");
              tempCheckedDates = newDates;
            }
          } else if (
            moment(options.date, "MM-DD-YYYY").isSame(
              moment(tempEndDate, "MM-DD-YYYY"),
              "day"
            )
          ) {
            // get dates that dont include end date
            let newDates = tempCheckedDates.filter(
              x =>
                !moment(x.date, "MM-DD-YYYY").isSame(
                  moment(options.date, "MM-DD-YYYY"),
                  "day"
                )
            );
            // if that was the last date
            if (newDates.length === 0) {
              tempStartDate = null;
              tempEndDate = null;
              tempCheckedDates = [];
              // if there's only one date
            } else if (newDates.length === 1) {
              tempStartDate = moment(options.date, "MM-DD-YYYY")
                .subtract(1, "days")
                .format("L");
              tempEndDate = null;
              tempCheckedDates = [
                { spotId: options.spotId, date: tempStartDate }
              ];
              // if there's more than one date
            } else {
              tempEndDate = moment(options.date, "MM-DD-YYYY")
                .subtract(1, "days")
                .format("L");
              tempCheckedDates = newDates;
            }
          }
        } else {
          // end date hasn't been set, so has to only be one day.
          // now array is empty, reset vars
          tempCheckedDates = [];
          tempStartDate = null;
          tempEndDate = null;
          tempSelectedSpotId = null;
        }
      } else if (
        // if they add an additional date
        // need to assess gaps
        tempCheckedDates.filter(
          x =>
            x.spotId === options.spotId &&
            !moment(x.date, "MM-DD-YYYY").isSame(
              moment(options.date, "MM-DD-YYYY"),
              "day"
            )
        ).length > 0
      ) {
        let newStartDate = null;
        let newEndDate = null;
        // if the selected date is before the start date, set that to the new start date
        if (
          moment(options.date, "MM-DD-YYYY").isBefore(
            moment(tempStartDate, "MM-DD-YYYY"),
            "day"
          )
        ) {
          newStartDate = options.date;
          // if there's already an end date, keep it
          if (tempEndDate) {
            newEndDate = tempEndDate;
            // if not, that means the array only has a length of two now
            // new end date is the start date
          } else {
            newEndDate = tempStartDate;
          }
        } else {
          // selected date is after start date, so its the new end date no matter what
          newStartDate = tempStartDate;
          newEndDate = options.date;
        }
        // check for unavailable dates between start and end date
        let newDates = [];
        let available = true;
        for (
          let day = moment(newStartDate, "MM-DD-YYYY");
          day.isSameOrBefore(moment(newEndDate, "MM-DD-YYYY"), "day");
          day.add(1, "days")
        ) {
          if (
            // if there's a matching spot and date in the unavailable dates
            // something isn't available
            tempUnavailableDates.filter(
              x =>
                x.spotId === options.spotId &&
                moment(x.date, "MM-DD-YYYY").isSame(day, "day")
            ).length > 0
          ) {
            available = false;
          }
          newDates.push({ spotId: options.spotId, date: day.format("L") });
        }
        // if nothing came back unavailable, set the dates
        if (available) {
          tempStartDate = newStartDate;
          tempEndDate = newEndDate;
          tempCheckedDates = newDates;
        }
      } else if (
        tempCheckedDates.filter(x => x.spotId !== options.spotId).length > 0
      ) {
        // if checked dates has a spot that doesn't match input
        // replace array, cant have two spots selected
        tempCheckedDates = [options];
        tempStartDate = options.date;
        tempEndDate = this.isDayUse ? options.date : null;
        tempSelectedSpotId = options.spotId;
      }
      // after all scenario checking, commit
      this.checkedDates = tempCheckedDates;
      this.$store.commit("search/setSearchDate", {
        key: "startDate",
        value: tempStartDate
      });
      this.$store.commit("search/setSearchDate", {
        key: "endDate",
        value: tempEndDate
      });
      this.$store.commit("search/setSelectedSpotId", tempSelectedSpotId);
    }
  },
  components: {
    AdminSpotResultsCalendarItem,
    SpotResultsCalendarItem,
    InfiniteLoading,
    SpotResultsCalendarWeekSelection
  }
};
</script>
