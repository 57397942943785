<template>
  <div class="my-2">
    <div
      class="d-flex center"
      :class="listItemClass"
      :id="'spot' + spot.id"
      @click="selectItem"
    >
      <div class="my-auto ml-2">
        <i
          :class="iconClassNames"
          class="productTypeIcon"
          role="presentation"
        />
      </div>
      <div class="my-auto mr-auto ml-2 spot-info">
        <p class="site-name">{{ spot.name }}</p>
        <p>{{ spot.loop }}</p>
        <p>
          <span
            v-b-popover.hover.top="
              spot.product.productClassificationDescription
            "
            :title="
              spot.product.productClassificationDescription
                ? 'Class Description'
                : ''
            "
            >{{ spot.product.productClassificationName }}</span
          >
          ${{ spot.lowerDailyRate
          }}{{
            spot.higherDailyRate !== spot.lowerDailyRate
              ? " - $" + spot.higherDailyRate
              : ""
          }}
          (per day)
        </p>
      </div>
      <div class="my-auto">
        <div class="m-0 maxPeople" v-if="maxPeople">
          Max. {{ maxPeople }}
          <img
            :src="require('@/assets/images/icons/people.svg')"
            alt="People Icon"
            height="20px"
            width="20px"
          />
        </div>
        <div class="attribute-icons float-right">
          <i
            v-for="attribute in iconAttributes"
            :key="attribute.id"
            :class="attribute.icon"
            class="pr-1"
            v-b-popover.hover.top="attribute.name"
          ></i>
        </div>
      </div>
      <div class="ml-1" style="width: 121px">
        <div v-if="spot.isSpotAvailable && spot.isSpotAvailable === true">
          <Reserve
            :reservedSpotId="spot.id"
            :locationId="spot.locationId"
            :locationName="spot.locationName"
            :editView="editView"
            :squareClass="'btn-square'"
            :isSpotAvailable="spot.isSpotAvailable"
            :adaRequiredSpot="adaRequiredSpot"
            :isDayUse="isDayUse"
          />
        </div>
        <div v-else>
          <button
            type="button"
            @click="switchToCalendar(spot.id)"
            class="btn btn-square btn-primary d-flex w-100 center view-dates"
          >
            <span class="mr-auto">View Dates</span>
            <i class="ml-1 my-auto fas fa-calendar-alt"></i>
          </button>
        </div>
        <SpotDetailsModal
          :spotId="spot.id"
          :name="spot.name"
          :squareButton="true"
        />
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import Reserve from "@/components/parkdetails/Reserve.vue";
import LocationService from "@/services/LocationService.js";
import SpotDetailsModal from "@/components/parkdetails/SpotDetailsModal.vue";

export default {
  name: "SpotResultsListItem",
  props: {
    spot: Object,
    locationName: String,
    editView: { type: Boolean, default: false }
  },
  components: {
    Reserve,
    SpotDetailsModal
  },
  data() {
    return {
      showDetails: false
    };
  },
  computed: {
    tenantInfo() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    iconAttributes() {
      const locationService = new LocationService(
        this.spot.tenantId,
        this.spot.locationId
      );
      return locationService.getIconAttributesToDisplay(this.spot);
    },
    listItemClass() {
      if (this.spot.isSelected) {
        return "list-item-selected";
      }
      return "list-item";
    },
    iconClassNames() {
      let iconClassNames = !this.spot.product.productTypeIcon
        ? this.tenantInfo.defaultProductImageUrl
        : this.spot.product.productTypeIcon;
      if (this.spot.isSelected) {
        iconClassNames += " selected";
      }
      return iconClassNames;
    },
    adaRequiredSpot() {
      const adaRequired = this.spot.spotAttributes.find(
        x => x.name === "ADA Occupant Required"
      );
      return adaRequired ? adaRequired.value === "yes" : false;
    },
    maxPeople() {
      const maxPeople = this.spot.spotAttributes.find(
        x => x.name === "Maximum Number of People"
      );
      return maxPeople ? maxPeople.value : null;
    },
    isDayUse() {
      return this.spot.product.bookingTypeId === 2; //Day Use
    }
  },
  watch: {
    "spot.isSelected": {
      handler() {
        if (this.spot.isSelected) {
          let element = document.getElementById("spot" + this.spot.id);
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
          });
        }
      }
    }
  },
  methods: {
    selectItem() {
      this.$emit("selected", this.spot.id);
      this.$store.commit("search/setSelectedSpotIsDayUse", this.isDayUse);
      //this.isSelected = !this.isSelected;
    },
    switchToCalendar(spotId) {
      this.$store.commit("search/setSearchView", "cal");
      this.$store.commit("search/setSelectedSpotId", spotId);
    }
  },
  mounted() {
    if (this.spot.isSelected) {
      let element = document.getElementById("spot" + this.spot.id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
    }
  }
};
</script>
<style scoped>
.spot-info p {
  margin: 0;
  line-height: 1.1;
}
.btn-gray {
  background-color: #eee;
}
.list-item {
  border: 2px solid #eee;
}
.list-item-selected {
  border: 2px solid #1078a8;
}
.site-name {
  color: #1078a8;
}
.attribute-icons {
  color: #999;
  font-size: 0.8rem;
  margin-top: 28px;
}

.maxPeople + .attribute-icons {
  margin-top: 12px;
}

.productTypeIcon {
  font-size: 1.5rem;
  color: #999;
}
.selected {
  color: #1078a8;
}
.center {
  align-items: center;
}
.view-dates {
  background-color: #666666 !important;
  border-color: #666666 !important;
}
</style>
