<template>
  <div class="my-2">
    <div class="d-flex flex-wrap row" :id="`cal-item-spot-${this.spot.id}`">
      <div class="col-lg-3 mt-auto pl-4 pt-2">
        <div class="d-flex text-left">
          <div>
            <div class="m-0 text-wrap">
              <i :class="iconClassNames" class="productTypeIcon mr-1" />
              <span class="site-name">{{ spot.name }}</span
              ><span
                title="Class Description"
                v-b-popover.hover.top="
                  spot.product.productClassificationDescription
                "
              >
                - {{ spot.product.productClassificationName }}
              </span>
              <span
                ><span class="font-weight-bolder">Sales Channel:</span>
                {{
                  spot.salesChannel === "Agency"
                    ? "Agency Only"
                    : spot.salesChannel
                }}
              </span>
            </div>
            <div class="m-0 text-wrap">
              {{ spot.area ? spot.area + " - " : "" }} {{ spot.loop }}
            </div>
            <div class="m-0 mb-2 text-wrap">
              <div v-if="drivewayLength">
                Driveway Length: {{ drivewayLength }} ft.<span
                  v-if="drivewayLength && maxPeople"
                >
                  |
                </span>
                <span v-if="maxPeople">
                  Max. {{ maxPeople }}
                  <img
                    :src="require('@/assets/images/icons/people.svg')"
                    alt="People Icon"
                    height="15px"
                    width="15px"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 d-flex flex-row mt-auto">
        <div
          v-for="(avail, index) in spotAvailabilities"
          :key="index"
          class="text-center day"
          v-b-popover.hover.top="getAvail(avail)"
        >
          <div :style="getCheckboxStyle(avail, index)" class="pt-1">
            <div
              :class="showInfoIcon(index) ? 'mt-1' : 'pt-4'"
              @click="calItemSelected(avail, index)"
            >
              <div v-if="avail.availabilityCode === 'Unavailable'">
                <div class="info-circle-container">
                  <AdminReservationsOnDateModal
                    :spotId="spot.id"
                    :date="avail.availabilityDate"
                    v-if="showInfoIcon(index)"
                  />
                </div>
                <i
                  class="align-middle fas fa-fw fa-ban unavailable font-thirty"
                ></i>
              </div>
              <div v-else-if="avail.availabilityCode === 'Reserved'">
                <a
                  href="javascript:void(0);"
                  role="checkbox"
                  class="p-0"
                  :aria-label="avail.availabilityDate"
                  :aria-checked="
                    isChecked(avail.availabilityDate) == true ? 'true' : 'false'
                  "
                  v-if="previousDayAvailable(index)"
                >
                  <div class="info-circle-container">
                    <AdminReservationsOnDateModal
                      :spotId="spot.id"
                      :date="avail.availabilityDate"
                      v-if="showInfoIcon(index)"
                    />
                  </div>
                  <i
                    class="align-middle far fa-calendar-check font-thirty"
                    :class="
                      isChecked(avail.availabilityDate) == true
                        ? 'fas text-primary'
                        : ''
                    "
                  ></i>
                </a>
                <div v-else>
                  <div class="info-circle-container">
                    <AdminReservationsOnDateModal
                      :spotId="spot.id"
                      :date="avail.availabilityDate"
                      v-if="showInfoIcon(index)"
                    />
                  </div>
                  <i
                    class="align-middle far fa-calendar-check unavailable font-thirty"
                  ></i>
                </div>
              </div>
              <div v-else-if="avail.availabilityCode === 'Walkin'">
                <a
                  href="javascript:void(0);"
                  role="checkbox"
                  class="p-0"
                  :aria-label="avail.availabilityDate"
                  :aria-checked="
                    isChecked(avail.availabilityDate) == true ? 'true' : 'false'
                  "
                >
                  <div
                    :class="
                      showInfoIcon(index)
                        ? 'walk-in-container'
                        : 'walk-in-height'
                    "
                  >
                    <div>
                      <AdminReservationsOnDateModal
                        :spotId="spot.id"
                        :date="avail.availabilityDate"
                        v-if="showInfoIcon(index)"
                      />
                    </div>
                    <div class="fa-stack">
                      <i
                        class="far fa-circle fa-stack-1x walk-in-circle"
                        :class="
                          isChecked(avail.availabilityDate) == true
                            ? 'fas text-primary'
                            : ''
                        "
                      >
                      </i>
                      <i
                        class="align-middle fas fa-walking fa-stack-1x small-walking"
                        :class="
                          isChecked(avail.availabilityDate) == true
                            ? 'fa-inverse'
                            : 'fas text-primary'
                        "
                      ></i>
                    </div>
                  </div>
                </a>
              </div>
              <div v-else-if="avail.availabilityCode === 'Closed'">
                <i
                  class="align-middle fas fa-times unavailable font-thirty"
                ></i>
              </div>
              <div v-else-if="avail.availabilityCode === 'ClosedOverridable'">
                <a
                  href="javascript:void(0);"
                  role="checkbox"
                  class="p-0"
                  :aria-label="avail.availabilityDate"
                  :aria-checked="
                    isChecked(avail.availabilityDate) == true ? 'true' : 'false'
                  "
                >
                  <i
                    class="align-middle fa-fw fa-times-circle font-thirty"
                    :class="
                      isChecked(avail.availabilityDate) == true
                        ? 'fas text-primary'
                        : 'far'
                    "
                  ></i>
                </a>
              </div>
              <div v-else>
                <a
                  href="javascript:void(0);"
                  role="checkbox"
                  class="p-0"
                  :aria-label="avail.availabilityDate"
                  :aria-checked="
                    isChecked(avail.availabilityDate) == true ? 'true' : 'false'
                  "
                >
                  <div class="info-circle-container">
                    <AdminReservationsOnDateModal
                      :spotId="spot.id"
                      :date="avail.availabilityDate"
                      v-if="showInfoIcon(index)"
                    />
                  </div>
                  <i
                    class="align-middle font-thirty"
                    :class="[
                      isChecked(avail.availabilityDate) == true
                        ? 'fas text-primary'
                        : 'far',
                      avail.availableAfterMaxArrival
                        ? 'fas fa-font'
                        : 'fa-fw fa-check-circle'
                    ]"
                  ></i>
                </a>
              </div>
              <p
                class="m-0 pt-1 daily-rate"
                :class="isChecked(avail.availabilityDate) ? ' selected' : ''"
              >
                ${{ avail.baseDailyRate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 mt-auto">
        <SpotDetailsModal :spotId="spot.id" :name="spot.name" class="mb-2" />
        <Reserve
          :reservedSpotId="spot.id"
          :locationId="spot.locationId"
          :locationName="spot.locationName"
          :adminView="adminView"
          :walkInReservation="walkInReservation"
          :editView="editView"
          :spotReservationId="spotReservationId"
          :spotAvailabilities="spotAvailabilities"
          :calendarView="true"
          :adaRequiredSpot="adaRequiredSpot"
          :isDayUse="isDayUse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Reserve from "@/components/parkdetails/Reserve.vue";
import LocationService from "@/services/LocationService.js";
import SpotDetailsModal from "@/components/parkdetails/SpotDetailsModal.vue";
import AdminReservationsOnDateModal from "@/components/admin/AdminReservationsOnDateModal.vue";

export default {
  name: "AdminSpotResultsCalendarItem",
  props: {
    spot: Object,
    week: Array,
    spotAvailabilities: Array,
    checkedDates: Array,
    walkInReservation: { type: Boolean, default: false },
    editView: { type: Boolean, default: false },
    spotReservationId: { type: Number, default: null },
    calItemIndex: { type: Number, default: null }
  },
  components: {
    Reserve,
    SpotDetailsModal,
    AdminReservationsOnDateModal
  },
  data() {
    return {
      adminView: true
    };
  },
  mounted() {
    this.scrollToElement();
  },
  methods: {
    scrollToElement() {
      if (this.spot.id === this.selectedSpotId) {
        let element = document.getElementById(
          "cal-item-spot-" + this.selectedSpotId
        );
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start"
        });
      }
    },
    getAvail(avail) {
      // unavailable
      if (avail.availabilityCode === "Unavailable") {
        return "Unavailable";
      } else if (avail.availabilityCode === "Reserved") {
        return "Reserved";
      } else if (avail.availabilityCode === "Walkin") {
        return "Walk-In";
      } else if (avail.availabilityCode === "Closed") {
        return "Park Closed";
      } else if (avail.availabilityCode === "ClosedOverridable") {
        return "Park Closed";
      }
      // there's a start and end date
      else if (
        this.checkedDates.length >= 2 &&
        moment(avail.availabilityDate).isBetween(
          moment(this.checkedDates[0].date, "MM-DD-YYYY"),
          moment(
            this.checkedDates[this.checkedDates.length - 1].date,
            "MM-DD-YYYY"
          ),
          "day",
          "[]"
        ) &&
        this.spot.id === this.selectedSpotId
      ) {
        return `Check In: ${moment(
          this.checkedDates[0].date,
          "MM-DD-YYYY"
        ).format("L")} Check Out: ${moment(
          this.checkedDates[this.checkedDates.length - 1].date,
          "MM-DD-YYYY"
        ).format("L")}`;
      }
      // just a start date checked
      else if (
        this.checkedDates.length === 1 &&
        moment(avail.availabilityDate).isSame(
          moment(this.checkedDates[0].date, "MM-DD-YYYY")
        ) &&
        this.spot.id === this.selectedSpotId
      ) {
        return `Check In: ${moment(
          this.checkedDates[0].date,
          "MM-DD-YYYY"
        ).format("L")}`;
      }
      // available but not checked
      else {
        return "Available";
      }
    },
    showInfoIcon(availabilityIndex) {
      const avail = this.spot.spotAvailabilities[availabilityIndex];
      if (avail.availabilityCode == "Reserved") {
        return true;
      }
      let previousDay =
        availabilityIndex > 0
          ? this.spotAvailabilities[availabilityIndex - 1]
          : {
              availabilityDate: moment(avail.availabilityDate)
                .add(-1, "days")
                .format("L")
            };
      if (
        previousDay?.availabilityCode == "Reserved" &&
        avail.availabilityCode != "Reserved" &&
        !this.isDayUse
      ) {
        return true;
      } else if (
        previousDay?.availabilityCode != "Reserved" &&
        avail.availabilityCode == "Reserved" &&
        !this.isDayUse
      ) {
        return true;
      }
    },
    getCheckboxStyle(avail, index) {
      let checkboxStyle = this.setCheckboxStyleBorder(index);
      let previousDay =
        index > 0
          ? this.spotAvailabilities[index - 1]
          : {
              availabilityDate: moment(avail.availabilityDate)
                .add(-1, "days")
                .format("L")
            };
      let nextDay =
        index < this.spotAvailabilities.length - 1
          ? this.spotAvailabilities[index + 1]
          : {
              availabilityDate: moment(avail.availabilityDate)
                .add(1, "days")
                .format("L")
            };

      if (
        previousDay?.availabilityCode == "Reserved" &&
        avail.availabilityCode != "Reserved" &&
        !this.isDayUse
      ) {
        checkboxStyle += `cursor: pointer; background-color: ${
          this.isChecked(avail.availabilityDate)
            ? "rgb(203,228,238)"
            : `${this.evenIndex ? "white" : "rgb(244, 247, 249)"}`
        }; background-image: linear-gradient(to bottom right, #eee 50%, ${
          this.isChecked(avail.availabilityDate)
            ? "rgb(203,228,238)"
            : `${this.evenIndex ? "white" : "rgb(244, 247, 249)"}`
        } 50%);`;
      } else if (
        previousDay?.availabilityCode != "Reserved" &&
        avail.availabilityCode == "Reserved" &&
        !this.isDayUse
      ) {
        checkboxStyle += `cursor: pointer; background-color: ${
          this.isChecked(avail.availabilityDate)
            ? "rgb(203,228,238)"
            : `${this.evenIndex ? "white" : "rgb(244, 247, 249)"}`
        }; background-image: linear-gradient(to bottom right, ${
          this.isChecked(avail.availabilityDate)
            ? "rgb(203,228,238)"
            : `${this.evenIndex ? "white" : "rgb(244, 247, 249)"}`
        } 50%, #eee 50%);`;
      } else if (avail.availabilityCode == "Reserved") {
        checkboxStyle += "background-color: #eee;";
      } else if (
        this.isChecked(avail.availabilityDate) &&
        !this.isChecked(previousDay.availabilityDate) &&
        !this.isDayUse
      ) {
        checkboxStyle += `cursor: pointer; background-color: white; background-image: linear-gradient(to bottom right, ${
          this.evenIndex ? "white" : "rgb(244, 247, 249)"
        } 50%, rgb(203,228,238) 50%);`;
      } else if (
        this.isChecked(avail.availabilityDate) &&
        !this.isChecked(nextDay.availabilityDate) &&
        !this.isDayUse
      ) {
        checkboxStyle += `cursor: pointer; background-color: rgb(203,228,238); background-image: linear-gradient(to bottom right, rgb(203,228,238) 50%, ${
          this.evenIndex ? "white" : "rgb(244, 247, 249)"
        } 50%);`;
      } else if (this.isChecked(avail.availabilityDate)) {
        checkboxStyle += "cursor: pointer; background-color: rgb(203,228,238);";
      } else if (
        avail.availabilityCode != "Unavailable" &&
        !(avail.availabilityCode == "Walkin" && !this.adminView)
      ) {
        checkboxStyle += "cursor: pointer;";
      }

      return checkboxStyle;
    },
    setCheckboxStyleBorder(index) {
      let checkboxStyle = "";
      if (this.selectedSpotId !== this.spot.id) {
        checkboxStyle += "border: 1px solid #ddd;";
      } else if (index === 0) {
        checkboxStyle +=
          "border-top: 2px solid #1078a8; border-bottom: 2px solid #1078a8; border-left: 2px solid #1078a8; border-right: 1px solid #ddd;";
      } else if (index === this.spotAvailabilities.length - 1) {
        checkboxStyle +=
          "border-top: 2px solid #1078a8; border-bottom: 2px solid #1078a8; border-right: 2px solid #1078a8; border-left: 1px solid #ddd;";
      } else {
        checkboxStyle +=
          "border-top: 2px solid #1078a8; border-bottom: 2px solid #1078a8; border-right: 1px solid #ddd; border-left: 1px solid #ddd;";
      }
      return checkboxStyle;
    },
    calItemSelected(avail, index) {
      if (
        avail.availabilityCode === "Unavailable" ||
        avail.availabilityCode === "Closed" ||
        (avail.availabilityCode === "Reserved" &&
          !this.previousDayAvailable(index))
      ) {
        return;
      } else if (
        avail.availabilityCode === "Reserved" &&
        this.previousDayAvailable(index)
      ) {
        this.handleSelect(avail);
      } else {
        this.handleSelect(avail);
      }
    },
    handleSelect(avail) {
      this.$emit("onSelectDate", {
        spotId: this.spot.id,
        date: moment(avail.availabilityDate).format("L"),
        isDayUse: this.isDayUse
      });
      this.$store.commit("search/setSelectedSpotIsDayUse", this.isDayUse);
    },
    isChecked(date) {
      if (
        !this.checkedDates ||
        this.checkedDates.length == 0 ||
        this.checkedDates[0].spotId != this.spot.id
      ) {
        return false;
      }
      let itemDate = moment(date);
      let checkedDate = this.checkedDates.find(item => {
        let checkDate = moment(item.date, "MM-DD-YYYY");
        if (itemDate.isSame(checkDate, "day")) {
          return item;
        }
      });
      if (checkedDate != null) {
        return true;
      }
      return false;
    },
    isPreviousDayChecked(index) {
      let previousDay = index > 0 ? this.spotAvailabilities[index - 1] : null;
      return this.isChecked(previousDay.availabilityDate);
    },
    previousDayAvailability(index) {
      let previousDay = index > 0 ? this.spotAvailabilities[index - 1] : null;
      if (!previousDay || this.isDayUse) return "Unavailable";

      return previousDay.availabilityCode;
    },
    previousDayAvailable(index) {
      const availabilityCode = this.previousDayAvailability(index);
      if (this.adminView) {
        return (
          availabilityCode === "Available" ||
          availabilityCode === "Walkin" ||
          availabilityCode === "ClosedOverridable"
        );
      } else {
        return availabilityCode === "Available";
      }
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    selectedSpotId() {
      return this.$store.getters["search/selectedSpotId"];
    },
    iconAttributes() {
      const locationService = new LocationService(
        this.spot.tenantId,
        this.spot.locationId
      );
      return locationService.getIconAttributesToDisplay(this.spot);
    },
    iconClassNames() {
      let iconClassNames = this.spot.product.productTypeIcon;
      if (this.spot.id == this.selectedSpotId) {
        iconClassNames += " selected";
      }
      return iconClassNames;
    },
    maxPeople() {
      const maxPeople = this.spot.spotAttributes.find(x => x.id === 133);
      return maxPeople ? maxPeople.value : null;
    },
    adaRequiredSpot() {
      const adaRequired = this.spot.spotAttributes.find(
        x => x.name === "ADA Occupant Required"
      );
      return adaRequired ? adaRequired.value === "yes" : false;
    },
    drivewayLength() {
      const drivewayLength = this.spot.spotAttributes.find(x => x.id === 7);
      return drivewayLength ? drivewayLength.value : null;
    },
    isDayUse() {
      return this.spot.product.bookingTypeId === 2; //Day Use
    },
    evenIndex() {
      return this.calItemIndex % 2 === 0;
    }
  },
  watch: {
    selectedSpotId() {
      this.scrollToElement();
    }
  }
};
</script>
<style scoped>
.list-item {
  border: none;
}
.list-item-selected {
  border: 2px solid #1078a8;
}
.btn-gray {
  background-color: #eee;
}
.site-name {
  color: #1078a8;
  font-weight: bold;
}

.additional-info {
  background-color: #eee;
  color: #999;
  width: 85px;
}
.font-ten {
  font-size: 10px;
}
.font-fourteen {
  font-size: 14px;
}
.daily-rate {
  font-size: 16px;
  font-weight: bold;
  color: #6b6b6b;
}
.attribute-icon {
  color: #999;
  font-size: 14px;
  vertical-align: middle;
}
.font-thirty {
  font-size: 18px;
}
.unavailable {
  color: #999 !important;
}
.productTypeIcon {
  color: #999;
}
.spot-line-height {
  height: 1rem;
}
.icon-height {
  height: 48px;
}
.selected {
  color: #1078a8;
}
.day {
  flex: 1;
}
.small-walking {
  font-size: 11px;
}
.walk-in-circle {
  font-size: 18px;
}
.walk-in-height {
  height: 21px !important;
}
a:focus {
  outline: none !important;
}
.fa-stack {
  height: 2.2em;
}
.btn-min-width button {
  min-width: 125px;
}
.reserve-button {
  font-size: 22px;
}
.info-circle-container {
  max-height: 20px;
}
.walk-in-container {
  height: 41px !important;
}
@media (max-width: 600px) {
  .day {
    flex: 1 0 14%;
  }
}
</style>
