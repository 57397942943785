<template>
  <div>
    <div class="map">
      <div class="float-left satelliteToggle">
        <button type="button" class="btn btn-light" @click="toggleSatellite">
          {{ satelliteButtonText }}
        </button>
      </div>
      <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :attributionControl="false"
        :zoom="4"
        :center="coordinates"
        ref="map"
        @load="onMapLoaded"
      >
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
        <MglFullscreenControl position="top-right" />
        <MglScaleControl unit="imperial" />

        <MglMarker
          v-for="result in searchResults"
          :key="`result-${result.id}`"
          :coordinates="[result.longitude, result.latitude]"
          @click="markerClicked(result.id)"
          :ref="'marker' + result.id"
        >
          <MglPopup anchor="top" :offset="[0, 10]">
            <SpotMarkerPopup :spot="result" />
          </MglPopup>
          <div slot="marker" class="d-flex flex-column align-items-center">
            <i
              :class="markerIconClasses(result)"
              class="productTypeIcon text-outline-white"
            />
            <span
              class="text-outline-white text-center mt-n2"
              style="text-transform: uppercase"
              >{{ result.name }}</span
            >
          </div>
        </MglMarker>
        <MglMarker
          v-for="poi in poisWithIconsLatLong"
          :key="`poi-${poi.id}`"
          :coordinates="[poi.longitude, poi.latitude]"
          :ref="'poi' + poi.id"
        >
          <div slot="marker" class="d-flex flex-column align-items-center">
            <i
              v-if="!poi.icon.endsWith('.svg')"
              class="text-outline-white poiIcon fa"
              :class="poi.icon"
            />
            <img
              :src="require(`@/assets/images/icons/${poi.icon}`)"
              :alt="poi.name"
              height="25px"
              width="25px"
              v-if="poi.icon.endsWith('.svg')"
            />
            <span
              class="markerText text-outline-white text-center poiName"
              style="text-transform: uppercase"
              >{{ poi.name }}</span
            >
          </div>
          <MglPopup anchor="top" :offset="[0, 10]">
            <div class="poi-popup">
              <h5>{{ poi.name }}</h5>
              <p>{{ poi.description }}</p>
            </div>
          </MglPopup>
        </MglMarker>
      </MglMap>

      <div class="legend">
        <a @click="toggleLegend">
          <h4>
            Legend
            <i
              class="fas float-right"
              :class="legendIconClass"
              role="presentation"
            ></i>
          </h4>
        </a>
        <div :class="showLegendClass" class="mt-2">
          <div>
            <i class="res-tent-icon" role="presentation" />
            Campsite
          </div>
          <div>
            <i class="res-shelter-icon" role="presentation" />
            Shelter
          </div>
          <div>
            <i class="res-cabin-icon" role="presentation" />
            Cabin
          </div>
          <div>
            <i class="res-group-icon" role="presentation" />
            Group Campsite
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import SpotMarkerPopup from "@/components/parkdetails/SpotMarkerPopup.vue";

import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl,
  MglMarker,
  MglPopup
} from "vue-mapbox";

export default {
  name: "SpotMap",
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl,
    MglMarker,
    MglPopup,
    SpotMarkerPopup
  },
  props: {
    searchResults: Array,
    locationLatitude: Number,
    locationLongitude: Number,
    zoomLevel: Number,
    pois: Array
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAP_API_KEY, // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/oneoutdoor/ckll1sar112r117p2rsbpbq3i", // your map style
      mapMarkerClickedId: null,
      showLegendClass: "d-none",
      legendIconClass: "fa-chevron-right",
      satelliteButtonText: "Satellite"
    };
  },
  computed: {
    coordinates() {
      let latitude = this.$store.getters["tenant/tenantInfo"].latitude;
      let longitude = this.$store.getters["tenant/tenantInfo"].longitude;
      //if (!this.locationLatitude || !this.locationLongitude) {
      return [longitude, latitude];
      // }
      //return [this.locationLongitude, this.locationLatitude];
    },
    selectedSpotTypes() {
      return this.$store.getters["search/selectedSpotTypes"];
    },
    selectedProductClassifications() {
      return this.$store.getters["search/selectedProductClassifications"];
    },
    selectedAttributes() {
      return this.$store.getters["search/selectedAttributes"];
    },
    isFiltered() {
      return (
        this.selectedSpotTypes?.length > 0 ||
        this.selectedProductClassifications?.length > 0 ||
        this.selectedAttributes?.length > 0
      );
    },
    poisWithIconsLatLong() {
      return this.pois?.filter(p => p.icon && p.latitude && p.longitude);
    }
  },
  methods: {
    toggleSatellite() {
      if (this.satelliteButtonText == "Satellite") {
        this.satelliteButtonText = "Map";
        this.mapStyle = "mapbox://styles/mapbox/satellite-v9";
        this.satelliteButtonClass = "btn-light";
      } else {
        this.satelliteButtonText = "Satellite";
        this.mapStyle = "mapbox://styles/oneoutdoor/ckll1sar112r117p2rsbpbq3i";
        this.satelliteButtonClass = "btn-primary";
      }
    },
    toggleLegend() {
      if (this.showLegendClass == "") {
        this.showLegendClass = "d-none";
        this.legendIconClass = "fa-chevron-right";
      } else {
        this.showLegendClass = "";
        this.legendIconClass = "fa-chevron-down";
      }
    },
    onMapLoaded() {
      let latitude = this.locationLatitude;
      let longitude = this.locationLongitude;
      this.pan(latitude, longitude);
    },
    markerClicked(spotId) {
      this.$store.commit("search/setSelectedSpotId", spotId);
      this.mapMarkerClickedId = spotId;
    },
    pan(latitude, longitude) {
      // let latitude = this.$store.getters["search/selectedLatitude"];
      // let longitude = this.$store.getters["search/selectedLongitude"];

      if (
        latitude != null &&
        longitude != null &&
        this.$refs.map.actions.panTo
      ) {
        this.$refs.map.actions.panTo([longitude, latitude]).then(() => {
          this.$refs.map.actions.zoomTo(this.zoomLevel);
        });
      }
    },
    markerIconClasses(resultItem) {
      let classNames = resultItem.product.productTypeIcon;
      if (resultItem.isSelected == true) {
        classNames += " selected";
      } else if (
        resultItem.isSpotAvailable == false ||
        (this.isFiltered == true && resultItem.isFilteredResult != true)
      ) {
        classNames += " unavailable";
      }

      return classNames;
    },
    togglePopups() {
      this.searchResults.forEach(spot => {
        if (spot.id == this.mapMarkerClickedId) {
          this.pan(spot.latitude, spot.longitude);
          return;
        }

        var refName = "marker" + spot.id;
        if (!this.$refs[refName] || !this.$refs[refName][0]) {
          return;
        }
        if (spot.isSelected) {
          this.pan(spot.latitude, spot.longitude);
          if (!this.$refs[refName][0].marker.getPopup().isOpen()) {
            this.$refs[refName][0].togglePopup();
          }
        } else {
          if (this.$refs[refName][0].marker.getPopup().isOpen()) {
            this.$refs[refName][0].togglePopup();
          }
        }
      });
      this.mapMarkerClickedId = null;
    }
  },
  watch: {
    searchResults: {
      deep: true,
      handler() {
        this.togglePopups();
      }
    },
    locationLatitude() {
      this.onMapLoaded();
    }
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  }
};
</script>

<style scoped>
.map {
  height: 900px;
  width: auto;
  position: relative;
}

@media (max-width: 600px) {
  .map {
    height: 400px;
  }
}
.button-unavailable {
  background-color: #1078a8;
  color: #ffffff;
  opacity: 0.5;
  width: 85px;
}
.additional-info {
  background-color: #eee;
  color: #999;
  width: 85px;
}
.attribute-icons {
  color: #999;
  font-size: 1rem;
}
.site-name {
  color: #1078a8;
  font-weight: bold;
}
.popup {
  width: 225px;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
.popup p {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}

.legend {
  background-color: #fff;
  border-radius: 3px;
  bottom: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 5px;
  position: absolute;
  right: 20px;
  z-index: 1;
  width: 150px;
}

.legend i {
  font-size: 1.2rem;
  vertical-align: middle;
}

.legend h4 {
  padding: 5px;
  background-color: #eee;
  margin-bottom: 0px;
}

.satelliteToggle {
  z-index: 1;
  top: 20px;
  left: 20px;
  position: absolute;
}

.poi-popup h5 {
  font-weight: bold;
}
.poi-popup p {
  line-height: 0.8;
  margin-bottom: 0.5rem;
}
.productTypeIcon {
  font-size: 1.5rem;
  color: #567e3a;
}
.poiIcon {
  font-size: 1.2rem;
}
.selected {
  color: #1078a8;
}
.unavailable {
  color: #777;
}
.text-outline-white {
  text-shadow: 1px 0 0 white, 0 -1px 0 white, 0 1px 0 white, -1px 0 0 white;
}
.poiName {
  font-size: 0.7rem;
}
</style>
