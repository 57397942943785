<template>
  <div>
    <ValidationObserver ref="amenitiesForm">
      <div class="row p-3">
        <div class="col-12 px-0 mb-1">
          <div class="mx-2">
            <div class="row my-2">
              <div class="col-6">
                <div v-for="attr in boolAttributes[0]" :key="attr.id">
                  <label>
                    <input
                      type="checkbox"
                      @change="toggle(attr.id)"
                      :checked="checked(attr.id)"
                      class="mr-1 ml-2"
                    />
                    {{
                      attr.displayName != null ? attr.displayName : attr.name
                    }}
                  </label>
                </div>
              </div>
              <div class="col-6">
                <div v-for="attr in boolAttributes[1]" :key="attr.id">
                  <label>
                    <input
                      type="checkbox"
                      @change="toggle(attr.id)"
                      :checked="checked(attr.id)"
                      class="mr-1 ml-2"
                    />
                    {{
                      attr.displayName != null ? attr.displayName : attr.name
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              v-for="attr in selectAttributes"
              :key="attr.id"
            >
              <label :for="attr.id">{{
                attr.displayName != null ? attr.displayName : attr.name
              }}</label>
              <select
                class="form-control form-control-lg"
                :id="attr.id"
                @change="handleSelect"
                :value="setValue(attr.id)"
              >
                <option value>Select...</option>
                <option
                  v-for="option in attr.attributeOptions"
                  :key="option.id"
                  :value="option.id"
                  >{{
                    attr.id === 9 && option.id !== 91 && option.id !== 96
                      ? option.value + " & Up"
                      : option.value
                  }}</option
                >
              </select>
            </div>
            <div
              class="form-group"
              v-for="attr in multiSelectAttributes"
              :key="attr.id"
            >
              <label :for="attr.id">{{
                attr.displayName != null ? attr.displayName : attr.name
              }}</label>
              <multiselect
                :value="setMultiSelectValue(attr.id)"
                @input="handleMultiSelect"
                label="value"
                track-by="id"
                :multiple="true"
                :id="attr.id"
                :ref="attr.name"
                open-direction="bottom"
                placeholder="Select..."
                :options="attr.attributeOptions"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :key="option.id">
                    <span>{{ option.value }}</span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span>
                </template>
              </multiselect>
            </div>
            <div
              class="form-group"
              v-for="attr in numericAttributes"
              :key="attr.id"
            >
              <TextInput
                :rules="`numeric|minNumber:0`"
                :name="attr.displayName != null ? attr.displayName : attr.name"
                :id="attr.id.toString()"
                @change="handleInput"
                :value="setValue(attr.id)"
              />
            </div>
            <div
              class="form-group"
              v-for="attr in textAttributes"
              :key="attr.id"
            >
              <label :for="attr.id">{{
                attr.displayName != null ? attr.displayName : attr.name
              }}</label>
              <input
                class="form-control form-control-lg"
                :id="attr.id"
                type="text"
                @change="handleInput"
                :value="setValue(attr.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import ParkSearchService from "@/services/ParkSearchService.js";
import Multiselect from "vue-multiselect";
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver } from "vee-validate";
export default {
  name: "SpotAttributeFilters",
  components: {
    Multiselect,
    TextInput,
    ValidationObserver
  },
  data() {
    return {
      attributes: []
    };
  },
  methods: {
    async getAttributes() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var parkSearchService = new ParkSearchService(null, null, tenantId);
      this.attributes = await parkSearchService.getAttributes({
        isSearchable: true
      });
    },
    toggle(id) {
      let attributes = this.selectedAttributes;
      if (this.selectedAttributes.map(x => x.id).includes(id)) {
        attributes = this.selectedAttributes.filter(item => item.id !== id);
      } else {
        attributes.push({
          id: id,
          value: "yes",
          selectedAttributeOptions: []
        });
      }
      this.$store.commit("search/setSelectedAttributes", attributes);
    },
    checked(id) {
      if (this.selectedAttributes.map(x => x.id).includes(id)) {
        return "checked";
      }
      return "";
    },
    handleSelect(e) {
      let attributes = this.selectedAttributes.filter(
        item => item.id !== +e.target.id
      );
      if (e.target.value !== "") {
        attributes.push({
          id: +e.target.id,
          value: null,
          selectedAttributeOptions: [+e.target.value]
        });
      }
      this.$store.commit("search/setSelectedAttributes", attributes);
    },
    handleMultiSelect(options, id) {
      let attributes = this.selectedAttributes.filter(item => item.id !== id);
      if (options.length > 0) {
        attributes.push({
          id: id,
          value: null,
          selectedAttributeOptions: options.map(x => x.id)
        });
      }
      this.$store.commit("search/setSelectedAttributes", attributes);
    },
    async handleInput(e) {
      const success = await this.$refs.amenitiesForm.validate();
      if (!success) return;

      let attributes = this.selectedAttributes.filter(
        item => item.id !== +e.target.id
      );
      if (e.target.value !== "") {
        attributes.push({
          id: +e.target.id,
          value: e.target.type === "text" ? e.target.value : +e.target.value,
          selectedAttributeOptions: []
        });
      }
      this.$store.commit("search/setSelectedAttributes", attributes);
    },
    setValue(id) {
      const attribute = this.selectedAttributes.find(x => x.id === id);
      if (!attribute) return "";

      return attribute.selectedAttributeOptions.length > 0
        ? attribute.selectedAttributeOptions[0]
        : attribute.value;
    },
    setMultiSelectValue(id) {
      const attribute = this.attributes.find(x => x.id === id);
      const selectedAttribute = this.selectedAttributes.find(x => x.id === id);
      if (selectedAttribute) {
        const options = attribute.attributeOptions.filter(o =>
          selectedAttribute.selectedAttributeOptions.includes(o.id)
        );
        return options;
      }
      return [];
    },
    setMultiSelectAutocomplete() {
      const self = this;
      this.multiSelectAttributes.forEach(x => {
        self.$refs[x.name][0].$refs.search.setAttribute("autocomplete", "off");
      });
    }
  },
  computed: {
    boolAttributes() {
      const attributes = this.attributes.filter(
        x => x.attributeValueType.name === "Yes/No"
      );
      return [attributes.slice(0, 2), attributes.slice(2, 4)];
    },
    selectAttributes() {
      return this.attributes.filter(
        x => x.attributeValueType.name === "Single Select"
      );
    },
    multiSelectAttributes() {
      return this.attributes.filter(
        x => x.attributeValueType.name === "Multi Select"
      );
    },
    numericAttributes() {
      return this.attributes.filter(
        x => x.attributeValueType.name === "Numeric Free"
      );
    },
    textAttributes() {
      return this.attributes.filter(
        x => x.attributeValueType.name === "String Free"
      );
    },
    selectedAttributes() {
      const selected = this.$store.getters["search/selectedAttributes"];
      return selected ? selected : [];
    }
  },
  async mounted() {
    await this.getAttributes();
    this.$nextTick(() => {
      this.setMultiSelectAutocomplete();
    });
  }
};
</script>

<style scoped></style>
