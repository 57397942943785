<template>
  <div>
    <div class="form-row col-12 text-left mx-0 mb-3 mt-0 p-0">
      <p class="m-0">Spot Types</p>
    </div>
    <div class="form-row justify-content-center">
      <div class="col-12 col-sm-12 col-md-6 form-group text-left m-0">
        <div v-for="type in leftSpotTypes" :key="type.id">
          <CheckboxParameter
            :id="type.id"
            :name="type.name"
            :icon="type.icon"
          />
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 form-group text-left m-0">
        <div v-for="type in rightSpotTypes" :key="type.id">
          <CheckboxParameter
            :id="type.id"
            :name="type.name"
            :icon="type.icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxParameter from "@/components/parkdetails/CheckboxParameter.vue";
import ParkSearchService from "@/services/ParkSearchService.js";

export default {
  name: "SpotSearchParameters",
  components: {
    CheckboxParameter
  },
  data() {
    return {
      spotTypes: []
    };
  },
  methods: {
    async getSpotTypes() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var parkSearchService = new ParkSearchService(null, null, tenantId);
      this.spotTypes = await parkSearchService.getSpotTypes();
    }
  },
  computed: {
    columnBreak() {
      var half = Math.floor(this.spotTypes.length / 2);
      var remainder = this.spotTypes.length % 2;
      return remainder != 0 ? half + remainder : half;
    },
    rightSpotTypes() {
      return this.spotTypes.slice(this.columnBreak);
    },
    leftSpotTypes() {
      return this.spotTypes.slice(0, this.columnBreak);
    }
  },
  mounted() {
    this.getSpotTypes();
  }
};
</script>
