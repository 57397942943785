<template>
  <div>
    <div class="header">
      <ParkSearchBar />
      <TenantAlert />
    </div>
    <div class="container-fluid">
      <Alert v-if="alert && alert.layer === 'detail'" :alert="alert" />
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        >
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/parksearchresults').catch(() => {})"
          >PARK SEARCH RESULTS</a
        >
        >
        {{
          locationDetails.longName ? locationDetails.longName.toUpperCase() : ""
        }}
      </div>

      <h2 class="park-name">{{ locationDetails.longName }}</h2>

      <div class="mb-2">
        <LocationAlert :locationId="+locationId" v-if="+locationId" />
      </div>
      <ValidationObserver
        ref="dateObserver"
        tag="form"
        id="locationDetailForm"
        @submit.prevent="onSubmit"
      >
        <div class="row mx-0">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 spotSearch mb-3"
            :class="calPadding"
          >
            <div class="header-bar mx-n2 pt-2 pl-2 mb-2">
              Find Your Campsite
            </div>
            <FormErrorAlert
              v-if="errors.length > 0"
              :errors="errors"
              :formRef="this.$refs.dateObserver"
            />
            <SpotSearch
              :spots="searchResults"
              :classifications="filteredClassifications"
              :locationName="locationDetails.longName"
              :locationId="locationDetails.id"
              :loadingSpots="loadingSpots"
              @loadSpots="loadSpots"
            />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
            <div class="mb-3 locationInformation">
              <LocationInformation :location="locationDetails" :pois="pois" />
            </div>
            <div class="mb-3">
              <SpotMap
                v-if="locationDetails.latitude && searchResults"
                :searchResults="searchResults"
                :locationLatitude="locationDetails.mapLatitude"
                :locationLongitude="locationDetails.mapLongitude"
                :zoomLevel="locationDetails.mapZoomLevel"
                :pois="pois"
              />
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import SpotSearch from "@/components/parkdetails/SpotSearch.vue";
import LocationInformation from "@/components/parkdetails/LocationInformation.vue";
import SpotMap from "@/components/parkdetails/SpotMap.vue";
import ParkSearchBar from "@/components/search/ParkSearchBar.vue";
import LocationService from "@/services/LocationService.js";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import Alert from "@/components/alert/Alert.vue";
import LocationAlert from "@/components/alert/LocationAlert.vue";
import TenantAlert from "@/components/alert/TenantAlert.vue";

export default {
  name: "LocationDetail",
  data() {
    return {
      locationDetails: {},
      searchResults: [],
      productClassifications: [],
      pois: [],
      errors: [],
      loadingSpots: false
    };
  },
  props: {
    locationId: String
  },
  components: {
    SpotSearch,
    LocationInformation,
    SpotMap,
    ParkSearchBar,
    ValidationObserver,
    FormErrorAlert,
    Alert,
    LocationAlert,
    TenantAlert
  },
  methods: {
    onSubmit() {
      this.$refs.dateObserver.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.dateObserver.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.dateObserver.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
        }
      });
    },
    loadLocation() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      locationService.getLocationDetails().then(response => {
        this.locationDetails = response;
        this.setPageTitle();
      });
    },
    loadPois() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      locationService.getPointsOfInterestForLocation().then(response => {
        this.pois = response.locationPointsOfInterest;
      });
    },
    loadSpots() {
      this.loadingSpots = true;
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      const searchParams = {
        locationId: this.locationId,
        startDate: this.startDate,
        endDate: this.endDate,
        lockCode: this.lockCode,
        selectedSpotTypes: this.selectedSpotTypes,
        selectedProductClassifications: this.selectedProductClassifications,
        selectedAttributes: this.selectedAttributes,
        selectedSpotId: this.selectedSpotId,
        onlyShowAvailable: this.onlyShowAvailable
      };

      locationService.spotSearchForLocation(searchParams).then(response => {
        this.searchResults = response.spots ? response.spots : [];
        if (this.isFiltered) {
          this.searchResults.forEach(x => (x.isFilteredResult = true));
        }
        this.$store.commit(
          "search/setSpotsCount",
          this.searchResults ? this.searchResults.length : 0
        );
        this.loadingSpots = false;
        this.setSelectedSpotIdInSearchResults();
      });
    },
    loadProductClassifications() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      locationService.getClassificationsWithSpotTypes().then(response => {
        this.productClassifications =
          response.productClassificationsWithSpotTypes;
      });
    },
    initialize() {
      this.checkErrorQueue();
      this.loadLocation();
      this.loadSpots();
      this.loadProductClassifications();
      this.loadPois();
    },
    setSelectedSpotIdInSearchResults() {
      const newValue = this.selectedSpotId;
      //Unset previously selectes spotId
      const result = this.searchResults.find(item => item.isSelected);
      if (result) {
        result.isSelected = false;
      }

      //Set new id
      const newSelected = this.searchResults.find(item => item.id == newValue);
      if (newSelected != null) {
        this.$set(newSelected, "isSelected", true);
      }
    },
    setPageTitle() {
      const tenant = this.$store.getters["tenant/tenantInfo"];
      document.title =
        tenant.siteTitle +
        " - Location Details" +
        " - " +
        this.locationDetails.longName;
    },
    checkErrorQueue() {
      const errors = this.$store.getters["alert/errorQueue"];
      errors.forEach(x => {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: x,
          layer: "detail"
        });
      });
      this.$store.commit("alert/setErrorQueue", []);
    }
  },
  computed: {
    filteredClassifications() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      let filteredClassifications = locationService.filterClassificationsFromSpotTypes(
        this.selectedSpotTypes,
        this.productClassifications
      );
      return filteredClassifications;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    selectedSpotTypes() {
      return this.$store.getters["search/selectedSpotTypes"];
    },
    selectedProductClassifications() {
      return this.$store.getters["search/selectedProductClassifications"];
    },
    selectedAttributes() {
      return this.$store.getters["search/selectedAttributes"];
    },
    startDate() {
      return this.$store.getters["search/startDate"];
    },
    endDate() {
      return this.$store.getters["search/endDate"];
    },
    selectedSpotId() {
      return this.$store.getters["search/selectedSpotId"];
    },
    onlyShowAvailable() {
      return this.$store.getters["search/onlyShowAvailable"];
    },
    calPadding() {
      const searchView = this.$store.getters["search/searchView"];
      return searchView === "cal" ? "calPadding" : "";
    },
    view() {
      return this.$store.getters["search/searchView"];
    },
    alert: {
      cache: false,
      get() {
        return this.$store.getters["alert/errorAlert"];
      }
    },
    lockCode() {
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      if (claimData == null) {
        return null;
      }
      return claimData.lockCode;
    },
    isFiltered() {
      return (
        this.selectedSpotTypes?.length > 0 ||
        this.selectedProductClassifications?.length > 0 ||
        this.selectedAttributes?.length > 0
      );
    }
  },
  watch: {
    locationId() {
      this.$store.commit("search/setSelectedProductClassifications", null);
      this.$store.commit("search/setSelectedPointsOfInterest", null);
      this.$store.commit("search/setSelectedAttributes", null);
      this.$store.commit("search/setSelectedSpotTypes", null);
      this.$store.commit("search/setOnlyShowAvailable", false);
      this.$store.commit("search/setSpotSearchHasBeenClicked", false);
      this.initialize();
    },
    selectedSpotTypes() {
      this.$store.commit("search/setSelectedProductClassifications", null);
    },
    selectedProductClassifications(newValue) {
      if (newValue) {
        this.loadSpots();
      }
    },
    selectedSpotId(newValue) {
      this.setSelectedSpotIdInSearchResults(newValue);
    },
    view(newValue) {
      if (newValue == "list") {
        this.loadSpots();
      }
    }
  },
  created() {
    this.$store.commit("search/setSearchView", "list");
    this.$store.commit("search/setSelectedSpotId", null);
    this.initialize();
  },
  destroyed() {
    this.$store.commit("search/setSpotSearchHasBeenClicked", false);
  }
};
</script>
<style scoped>
.spotSearch {
  background-color: white;
}
.header-bar {
  height: 40px;
  background-color: #1078a8;
  color: #fff;
}
@media (min-width: 1000px) {
  .container-fluid {
    width: 90%;
  }
}

.park-name {
  color: #1078a8;
  z-index: 11;
  text-transform: uppercase;
  margin: 1em 0;
  font-size: 2em;
}

@media (max-width: 600px) {
  .park-name {
    font-size: 1.25em;
  }

  .calPadding {
    padding-bottom: 3rem;
  }
}
</style>
